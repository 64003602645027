import "./trade.scss";

export const ToggleOptions = ({
  selectedItem,
  formValues,
  handleItemClick,
}) => {
  return (
    <>
      <div className="gas-price-lists-box slippage-box mb-3">
        <div className="horizontal-list">
          <div className="list-item">
            <div className="radio-text Slippage-sec">
              <span
                className={selectedItem === "+%" ? "selected" : ""}
                onClick={() => handleItemClick("+%", "increase_by")}
              >
                +%
              </span>
              <span
                className={selectedItem === "-%" ? "selected" : ""}
                onClick={() => handleItemClick("-%", "decrease_by")}
              >
                -%
              </span>
              <span
                className={
                  selectedItem === `+${formValues.stableToken}`
                    ? "selected"
                    : ""
                }
                onClick={() =>
                  handleItemClick(
                    `+${formValues.stableToken}`,
                    "fixed_increase"
                  )
                }
              >
                +{formValues.stableToken}
              </span>
              <span
                className={
                  selectedItem === `-${formValues.stableToken}`
                    ? "selected"
                    : ""
                }
                onClick={() =>
                  handleItemClick(
                    `-${formValues.stableToken}`,
                    "fixed_decrease"
                  )
                }
              >
                -{formValues.stableToken}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
