import "./CheatSheetSlider.scss";
import { addCommasToNumber } from "../../utils/format-util";

const CheatColumnCard = ({ cheatsheetDetails }) => {
  if (!cheatsheetDetails) return;
  const columnItems = [
    {
      id: 1,
      value: `$ ${addCommasToNumber(cheatsheetDetails?.vwap)}`,
      label: "Daily VWAP",
    },
    {
      id: 2,
      value: `$ ${addCommasToNumber(cheatsheetDetails?.simpleMovingAvg)}`,
      label: `${cheatsheetDetails?.simpleMovingAvgDays} SMA`,
    },
    {
      id: 3,
      value: `$ ${addCommasToNumber(cheatsheetDetails?.expMovingAvg)}`,
      label: `${cheatsheetDetails?.expMovingAvgDays} EMA`,
    },
    {
      id: 4,
      value: `${cheatsheetDetails?.rsi}`,
      label: "RSI",
    },
  ];
  return (
    <>
      {columnItems.map((items, key) => (
        <div className="col-md-3" key={key.id}>
          <div className="bg-container mb-md-0 mb-3">
            {/*<p>Total In colums</p>*/}
            <h3>{items.value}</h3>
            <p>{items.label}</p>
            {/* <span>{items.expiry}</span>*/}
          </div>
        </div>
      ))}
    </>
  );
};

export default CheatColumnCard;
