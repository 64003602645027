// CustomCheckbox.js
import React, { useState, useEffect } from "react";
import "./CustomCheckbox.scss";
import checkmarkSvg from "../../Assets/checkmark.svg"; // Import the SVG icon
import Tooltip from "../Tooltips/Tooltip";

const CustomCheckbox = ({
  handleChange,
  value,
  name,
  label,
  setIsDCAEnabledForBuy,
  setIsDCAEnabledForSell,
  formValues,
}) => {
  const [isChecked, setIsChecked] = useState(value);

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
    name && handleChange(name, !isChecked);
    setIsDCAEnabledForBuy && setIsDCAEnabledForBuy(!isChecked);
    setIsDCAEnabledForSell && setIsDCAEnabledForSell(!isChecked);
  };

  useEffect(() => {
    if (name) setIsChecked(formValues[name]);
  }, [name ? formValues[name] : null])

  return (
    <label className="custom-checkbox mb-0 d-flex align-items-center">
      <input
        type="checkbox"
        className="checkbox-input"
        checked={isChecked}
        onChange={toggleCheckbox}
      />
      <span className="checkbox-icon">
        {isChecked && (
          <img src={checkmarkSvg} alt="Checkmark" className="checkmark-icon" />
        )}
      </span>
      {label}
    </label>
  );
};

export default CustomCheckbox;
