import { useEffect, useState } from "react";
import DropdownSelect from "../DropdownSelect/DropdownSelect";
import SelectDialogStrategy from "../SelectWithDialog/SelectDialogStrategy";
import { getTokensEndpoint } from "../../api/endpoints";
import Input from "../Input/Input";
import debounce from "lodash.debounce";

const StrategyFilter = ({
  onSelectStatus,
  onSelectFilterToken,
  onAddressEntered,
}) => {
  const [tokenOptions, setTokenOptions] = useState([]);
  const [selectedToken, setSelectedToken] = useState(); // token which is selected from dropdown
  const [walletAddress, setWalletAddress] = useState("");
  const [isTokensLoading, setIsTokensLoading] = useState(true); // Added isLoading state

  const options = [
    { label: "All", value: "all", symbol: "live-svg.svg" },
    { label: "Active", value: "active", symbol: "status-1.svg" },
    { label: "Cancelled", value: "cancelled", symbol: "status-2.svg" },
    { label: "Completed", value: "completed", symbol: "status-3.svg" },
    { label: "Historical", value: "historical", symbol: "status-4.svg" },
  ];
  const handleSelect = (option) => {
    onSelectStatus(option.value === "all" ? null : option.value); // Pass the selected status to the parent (Analyse) component
  };
  const handleTokenSelect = (selectedToken) => {
    setSelectedToken(selectedToken);
    onSelectFilterToken(selectedToken?.symbol);
  };

  useEffect(() => {
    const fetchTokenOptions = async () => {
      try {
        setIsTokensLoading(true);
        const response = await fetch(getTokensEndpoint());
        if (response.ok) {
          const data = await response.json();
          setTokenOptions(data);
        } else {
          console.error("Failed to fetch token options");
        }
      } catch (error) {
        console.error("Error fetching token options:", error);
        setIsTokensLoading(false);
      } finally {
        setIsTokensLoading(false);
      }
    };

    fetchTokenOptions();
  }, []);

  const debouncedUpdateAddress = debounce((enteredAddress) => {
    onAddressEntered(enteredAddress);
  }, 1000); // delaying it to avoid api call for every input

  const handleAddressInputChange = (event) => {
    const enteredAddress = event.target.value;
    setWalletAddress(enteredAddress);
    debouncedUpdateAddress(enteredAddress); // Call the debounced function with the entered address
  };

  return (
    <>
      <div className="filter-div mt-3">
        <div className="row">
          <div className="col-md-3 col-6">
            <label>Status</label>
            <DropdownSelect
              className=""
              options={options}
              onSelect={handleSelect}
              placeholder="All"
            />
          </div>
          <div className="col-md-3 col-6">
            <label>Tokens</label>
            <SelectDialogStrategy
              options={tokenOptions}
              onSelect={handleTokenSelect}
              selectButtonLabel={
                selectedToken ? selectedToken?.symbol : "All Tokens"
              }
              selectedToken={selectedToken?.symbol}
            />
          </div>
          <div className="col-md-6">
            <label>Address</label>
            <Input
              type="text"
              placeholder="Type Here"
              value={walletAddress}
              onChange={handleAddressInputChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StrategyFilter;
