import { Row } from "react-bootstrap";
import Backicon from "../../Assets/back.svg";
import Input from "../Input/Input";
import "./AddWalletBalance.scss";
import ArrowInput from "../../Assets/arrow-in.svg";
import ArrowOutput from "../../Assets/arrow-out.svg";
import { useState, useEffect } from "react";
import {
  getAddBalanceEndpoint,
  getBalanceTransactionEndpoint,
  getTokenIconEndpoint,
} from "../../api/endpoints";
import CustomSnackbar from "../../components/CustomSnackbar";
import { formatDate } from "../../utils/format-util";
import { signMessage } from "../../utils/sign-utils";

const AddWalletBalance = ({
  walletItem,
  address,
  setIsBalanceAdded,
  onclick,
}) => {
  const [balance, setBalance] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiError, setApiError] = useState();
  const [transactions, setTransactions] = useState([]);

  const handleAmountChange = (event) => {
    setBalance(event.target.value);
  };

  const handleClose = (event, reason) => {
    // close snackbar
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleAddBalance = async () => {
    try {
      const signature = await signMessage(
        address,
        `Sign in to verify that you want to add ${balance} ${walletItem.token}`
      );
      if (signature) {
        if (!balance || balance <= 0) {
          // do api request only if valid balance is entered
          setOpenSnackbar(true);
          setApiError("Please enter amount to add balance");
          return;
        }
        const payload = {
          token: walletItem.token,
          amount: balance,
          address: address,
        };

        fetch(getAddBalanceEndpoint(), {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: signature,
          },
          body: JSON.stringify(payload),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            setApiError("");
            setOpenSnackbar(true);
            setBalance("");
            setIsBalanceAdded(true);
          })
          .catch((error) => {
            setApiError("An error occurred while adding balance");
            console.error("Error updating balance:", error);
          });
      } else {
        // the signature is not available here
        setOpenSnackbar(true);
        setApiError("Please sign the signature to add balance"); // to show red snackbar
      }
    } catch (error) {
      console.error("Error signing message:", error);
    }
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(
          getBalanceTransactionEndpoint(address, walletItem.token)
        );
        if (response.ok) {
          const data = await response.json();
          setTransactions(data);
        } else {
          console.error("Failed to fetch token options");
        }
      } catch (error) {
        console.error("Error fetching token options:", error);
      }
    };

    fetchTransactions();
  }, [address, walletItem]);

  return (
    <>
      {openSnackbar && (
        <CustomSnackbar
          open={openSnackbar}
          onClose={handleClose}
          message={apiError ? apiError : "Balance added successfully"}
          severity={apiError ? "error" : "success"}
        />
      )}
      <div className="content-box">
        <Row className="box-header justify-content-between">
          <div className="header-select">
            <img
              src={Backicon}
              onClick={onclick}
              alt="SVG back icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            <h2> Add Balance</h2>
          </div>
        </Row>
        <div className="balance-div text-center">
          <p>Available balance</p>
          <h2>
            {walletItem?.balance} {walletItem.token}
          </h2>
          <small>(${walletItem.totalBalanceUSD} USD)</small>

        </div>
        <div className="type-value balance-div px-4 align-items-center">
          <Input
            type="number"
            value={balance}
            placeholder="Enter Amount"
            onChange={handleAmountChange}
          />
          <button className="custom-white-button" onClick={handleAddBalance}>
            Add Balance
          </button>
        </div>
        <div className="balance-history">
          <p>Token History</p>
          {transactions ? (
            <ul className="balance-listing ps-0">
              {transactions
                .slice()
                .sort((a, b) => new Date(b.date) - new Date(a.date)) // sort transactions in recent first order
                .map((transaction, index) => (
                  <li
                    className="d-flex justify-content-between align-items-center"
                    key={index}
                  >
                    <div className="left-col d-flex">
                      <img
                        alt="back arrow icon"
                        src={
                          transaction?.reason === "IN"
                            ? ArrowInput
                            : ArrowOutput
                        }
                      />
                      <div className="token-list ms-4 d-flex align-items-center">
                        {/*transaction?.reason === "IN" &&
                          
                            <img
                            alt="token icon"
                            style={{
                              width: "38px",
                              height: "38px",
                            }}
                            src={getTokenIconEndpoint(transaction.token)}
                            onError={(e) => {
                              e.target.src = `https://coinicons-api.vercel.app/api/icon/${transaction.token?.toLowerCase()}`;
                            }}
                          />
                          */}
                        <p>{formatDate(transaction?.date)}</p>
                      </div>
                    </div>
                    <div className="right-col">
                      <h3>
                        {transaction.amount} {transaction.token}
                      </h3>
                      <p>(${transaction.totalBalanceInUSD})</p>
                    </div>
                  </li>
                ))}
            </ul>
          ) : (
            <p>No transactions available</p>
          )}
        </div>
      </div>
    </>
  );
};

export default AddWalletBalance;
