import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ListItemIcon,
  CircularProgress,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CircleIcon from "@mui/icons-material/Circle";
import Slide from "@mui/material/Slide";
import "./SelectwithDialog.scss";
import Backicon from "../../Assets/back.svg";
import SearchIcon from "@mui/icons-material/Search";
import TokenSelectIcon from "../../Assets/token-sel-icon.svg";
import { getTokenIconEndpoint } from "../../api/endpoints";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelectWithDialog = ({
  options,
  onSelect,
  selectedToken,
  selectButtonLabel,
  isTokensLoading,
  formValues,
}) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selectedOption, setSelectedOption] = useState("");

  const handleOpenDialog = () => {
    setOpen(true);
    setSearchValue("");
    setFilteredOptions(options);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);

    const filtered = options.filter(
      (option) =>
        option.name?.toLowerCase().includes(value?.toLowerCase()) ||
        option.symbol?.toLowerCase().includes(value?.toLowerCase())
    );

    setFilteredOptions(filtered);
  };

  const handleOptionClick = (option) => {
    // same token cannot be selected for both invest and stable dropdowns

    if (
      formValues && // formValues is only passed when this component is used in trade page
      (formValues.investToken === option.symbol ||
        formValues.stableToken === option.symbol)
    ) {
      handleCloseDialog();
    } else {
      setSelectedOption(option);
      onSelect(option);
      handleCloseDialog();
    }
  };

  return (
    <div className="Select-dialog-custom">
      <div>
        <Button
          className="select-input-style"
          onClick={handleOpenDialog}
          variant="outlined"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="icon-container">
            {selectedToken ? (
              <img
                alt={selectedToken}
                style={{
                  marginRight: "8px",
                  width: "28px",
                  height: "28px",
                }}
                src={getTokenIconEndpoint(selectedToken)}
                onError={(e) => {
                  e.target.src = `https://coinicons-api.vercel.app/api/icon/${selectedToken?.toLowerCase()}`;
                }}
              />
            ) : (
              <CircleIcon
                style={{ marginRight: "8px", color: "rgb(90, 98, 112)" }}
              />
            )}
            {selectButtonLabel}
          </div>
          <ArrowDropDownIcon />
        </Button>

        <Dialog
          className="dialog-style"
          open={open}
          onClose={handleCloseDialog}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            <div className="header-select">
              <img
                onClick={handleCloseDialog}
                src={Backicon}
                alt="SVG Icon"
                style={{ width: "24px", height: "24px", marginRight: "8px" }}
              />
              <h2> Select a Token</h2>
            </div>
          </DialogTitle>

          <DialogContent className="token-select-dialog">
            <TextField
              placeholder="Search by name or symbol"
              inputProps={{
                autoComplete: "off",
              }}
              value={searchValue}
              onChange={handleSearchChange}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <ListItemIcon className="search-placeholder">
                    <SearchIcon />
                  </ListItemIcon>
                ),
              }}
              sx={{
                // Custom styles here
                "& .MuiOutlinedInput-root": {
                  borderRadius: "20px",
                  border: "1px solid #7A603A",
                  color: "#fff",
                },
                "& .MuiInputBase-input": {
                  color: "white",
                },
              }}
            />
            <div className="select-opt-section">
              {isTokensLoading ? (
                <div className="text-center py-5">
                  <CircularProgress />
                </div>
              ) : (
                filteredOptions.map((option, index) => (
                  <div
                    className="token-lists"
                    key={index}
                    onClick={() => handleOptionClick(option)}
                  >
                    <div className="token-icon">
                      <ListItemIcon style={{ marginRight: "8px" }}>
                        <img
                          alt={option.symbol}
                          className=""
                          style={{ width: "38px", height: "38px" }}
                          src={getTokenIconEndpoint(option.symbol)}
                          onError={(e) => {
                            e.target.src = `https://coinicons-api.vercel.app/api/icon/${option.symbol?.toLowerCase()}`;
                          }}
                        />
                      </ListItemIcon>
                    </div>
                    <div className="token-label">
                      <h5>{option.symbol}</h5>
                      <small>{option.name}</small>
                    </div>
                    <div className="right-icon">
                      <img
                        src={TokenSelectIcon}
                        alt="Right Icon"
                        style={{ width: "26px", height: "26px" }}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
          </DialogContent>
          {/* <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions> */}
        </Dialog>
      </div>
    </div>
  );
};

export default SelectWithDialog;
