import React, { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
import "./Pagination.scss";

const PaginationItem = ({ onPageChange, totalPages }) => {
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    onPageChange(activePage);
  }, [activePage, onPageChange]);

  const handlePageClick = (page) => {
    setActivePage(page);
  };

  const calculateDisplayPages = () => {
    const displayPages = [];
    const totalDisplayPages = 7; // 7 looks fine for now, but might change if needed to adapt mobile

    let startPage = Math.max(1, activePage - Math.floor(totalDisplayPages / 2));
    let endPage = Math.min(totalPages, startPage + totalDisplayPages - 1);

    if (endPage - startPage < totalDisplayPages - 1) {
      startPage = Math.max(1, endPage - totalDisplayPages + 1);
    }

    if (startPage > 1) {
      displayPages.push(
        <Pagination.Item
          key={1}
          active={1 === activePage}
          onClick={() => handlePageClick(1)}
        >
          {1}
        </Pagination.Item>
      );
      if (startPage > 2) {
        displayPages.push(
          <Pagination.Ellipsis key="ellipsis-start" disabled />
        );
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      displayPages.push(
        <Pagination.Item
          key={number}
          active={number === activePage}
          onClick={() => handlePageClick(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        displayPages.push(<Pagination.Ellipsis key="ellipsis-end" disabled />);
      }
      displayPages.push(
        <Pagination.Item
          key={totalPages}
          active={totalPages === activePage}
          onClick={() => handlePageClick(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return displayPages;
  };

  return (
    <Pagination className="custom-pagination">
      <Pagination.Prev
        onClick={() => handlePageClick(Math.max(1, activePage - 1))}
        disabled={activePage === 1}
      />
      {calculateDisplayPages()}
      <Pagination.Next
        onClick={() => handlePageClick(Math.min(activePage + 1, totalPages))}
        disabled={activePage === totalPages}
      />
    </Pagination>
  );
};

export default PaginationItem;
