import React, { useState, useEffect } from "react";
import { NavLink, Row } from "react-bootstrap";
import Backicon from "../../Assets/back.svg";
import EmptyIcon from "../../Assets/empty-portfolio.svg";
import "./Trades.scss";
import {
  getTokenIconEndpoint,
  getTradesByStrategyEndpoint,
} from "../../api/endpoints";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/format-util";
import { CircularProgress } from "@mui/material";

const Trades = () => {
  const query = new URLSearchParams(useLocation().search);
  const strategyId = query.get("strategyId") || "";
  // const chainId = query.get("chainId") || "";
  const navigate = useNavigate();

  const [tradesData, setTradesData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    // Fetch data from the server
    const fetchData = async () => {
      setLoadingData(true);
      try {
        const response = await fetch(getTradesByStrategyEndpoint(strategyId));
        if (response.ok) {
          const data = await response.json();

          // fetch token symbols for each trade
          // const updatedTradesList = await Promise.all(
          //   data.map(async (item) => {
          //     const investToken = await getTokenSymbol(item.investToken);
          //     const stableToken = await getTokenSymbol(item.stableToken);

          //     return {
          //       ...item,
          //       investToken,
          //       stableToken,
          //     };
          //   })
          // );
          setTradesData(data);
        } else {
          setLoadingData(false);
          console.error("Failed to fetch trades data");
        }
      } catch (error) {
        setLoadingData(false);
        console.error("Error fetching trades data:", error);
      }
      setLoadingData(false);
    };

    fetchData();
  }, [strategyId]);

  const handleBackButtonClick = () => {
    navigate(`/strategy?id=${strategyId}`);
  };

  return (
    <>
      <div className="overlap-group">
        {loadingData ? (
          <div className="text-center py-4 w-100">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className=" wallet-container-outer pt-0">
              <Row className="box-header justify-content-between">
                <div className="header-select align-items-center">
                  <img
                    src={Backicon}
                    alt="SVG Icon"
                    style={{
                      width: "24px",
                      height: "24px",
                      marginRight: "18px",
                    }}
                    onClick={handleBackButtonClick}
                  />
                  <h2 className="m-0"> Trades</h2>
                </div>
              </Row>
              <div className="row">
                <div className="col-12">
                  {tradesData.length === 0 ? (
                    <div className="wallet-listing d-flex flex-wrap mx-auto">
                      <div className="d-flex h-100 align-items-center justify-content-center flex-column w-100 empty-div-portfolio">
                        <img src={EmptyIcon} alt="Icon" />
                        <h3 className="text-white">No Trades</h3>
                        <p>No trades were found for this strategy.</p>
                      </div>
                    </div>
                  ) : (
                    <table className="table-bordered w-100 mt-4 d-md-table d-none trade-table">
                      <thead>
                        <tr>
                          <th>Tokens</th>
                          <th>Amount</th>
                          <th>Date</th>
                          <th>Exchange Rate</th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tradesData.map((trade, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex">
                                <div className="me-4">
                                  <img
                                    src={getTokenIconEndpoint(
                                      trade.investToken
                                    )}
                                    onError={(e) => {
                                      e.target.src = `https://coinicons-api.vercel.app/api/icon/${trade.investToken?.toLowerCase()}`;
                                    }}
                                  />
                                  <p className="text-center">
                                    {trade.investToken}
                                  </p>
                                </div>
                                <div>
                                  <img
                                    src={getTokenIconEndpoint(
                                      trade.stableToken
                                    )}
                                    onError={(e) => {
                                      e.target.src = `https://coinicons-api.vercel.app/api/icon/${trade.stableToken?.toLowerCase()}`;
                                    }}
                                  />
                                  <p className="text-center">
                                    {trade.stableToken}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>${trade.totalBalanceInUSD}</td>
                            <td>{formatDate(trade.createdAt)}</td>
                            <td>${trade.exchangeRate}</td>
                            <td>
                              {" "}
                              <span
                                className={"" + `${trade.type.toLowerCase()}`}
                              >
                                {
                                  trade.type.charAt(0).toUpperCase() +
                                  trade.type.slice(
                                    1
                                  ) /* set first letter capital */
                                }
                                <i></i>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  <ul className="ps-0 d-md-none d-flex flex-wrap">
                    {tradesData.map((items, key) => (
                      <li
                        className="analyse-card-outer mt-4 trade-outer"
                        key={key.id}
                      >
                        <div className="analyse-container">
                          <div className="card-header d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <p className="me-3 d-flex align-items-center">
                                {" "}
                                <img
                                  src={getTokenIconEndpoint(items.investToken)}
                                  onError={(e) => {
                                    e.target.src = `https://coinicons-api.vercel.app/api/icon/${items.investToken?.toLowerCase()}`;
                                  }}
                                />
                                {items.investToken}
                              </p>
                              <p className="d-flex align-items-center">
                                {" "}
                                <img
                                  src={getTokenIconEndpoint(items.stableToken)}
                                  onError={(e) => {
                                    e.target.src = `https://coinicons-api.vercel.app/api/icon/${items.stableToken?.toLowerCase()}`;
                                  }}
                                />
                                {items.stableToken}
                              </p>
                              <span
                                className={
                                  "ms-3 " + `${items.type?.toLowerCase()}`
                                }
                              >
                                {
                                  items.type.charAt(0).toUpperCase() +
                                  items.type.slice(
                                    1
                                  ) /* set first letter capital */
                                }
                                <i></i>
                              </span>
                            </div>
                          </div>
                          <div className="card-body-content">
                            <ul className="analyse-inner-listing ps-0 mb-0 active">
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Amount</p>
                                  <div className="token-list d-flex align-items-center">
                                    <h3>${items.totalBalanceInUSD}</h3>
                                  </div>
                                </div>
                              </li>

                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Exchange rate</p>
                                  <h3>${items.exchangeRate}</h3>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Date</p>
                                  <h3>{formatDate(items.createdAt)}</h3>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Trades;
