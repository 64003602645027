import React, { useEffect, createContext } from "react";
import { Routes, Route } from "react-router-dom";
import Trade from "../src/Pages/Trade/Trade";
import Portfolio from "./Pages/Portfolio/Portfolio";
import Analyse from "./Pages/Analyse/Analyse";
import StrategyInformation from "./Pages/StrategyInfo/StrategyInfo";
import CheatSheet from "./Pages/CheatSheet/CheatSheet";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { sepolia } from "wagmi/chains";
import { Web3Modal } from "@web3modal/react";
import { useState } from "react";
import Trades from "./Pages/Trades/Trades";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
// import HeaderNew from "./Components/Header/Header-new";
// import { getSupportedChainsEndpoint } from "./api/endpoints";
// import { switchChain } from "./utils/switchChain-util";

export const ChainContext = createContext(); // Create a context for selected Chain Id from header dropdown
const chains = [sepolia]; // add polygon, optimism, arbitrum when supported
const projectId = process.env.REACT_APP_WALLET_CONNECT_API;

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);
const App = () => {
  const [walletConnected, setWalletConnected] = useState();
  const [selectedChainId, setSelectedChainId] = useState();
  const [selectedChain, setSelectedChain] = useState();
  const [isCntToNotSupportedChain, setIsCntToNotSupportedChain] =
    useState(false);
  const [connectedChainDetails, setConnectedChainDetails] = useState("");

  // set selectedChain as defaultChain (send to Web3Modal as prop) when the user selects chain from dropdown first and connects wallet then it will connect to selected chain directly
  // useEffect(() => {
  // if(Number(selectedChainId)===polygon.id) {setSelectedChain(polygon)}
  // if (Number(selectedChainId) === arbitrum.id) {
  //   setSelectedChain(arbitrum);
  // }
  // if(Number(selectedChainId)===optimism.id) {setSelectedChain(optimism)}
  // if (Number(selectedChainId) === sepolia.id) {
  //   setSelectedChain(sepolia);
  // }

  // if (Number(selectedChainId) === polygon.id) {
  //   setSelectedChain(polygon);
  // }
  // }, [selectedChainId]);

  // useEffect(() => {
  //   if (!walletConnected && !(walletConnected === undefined)) {
  //     setSelectedChainId(sepolia.id); // if wallet is not connected set default a chain
  //     setSelectedChain(sepolia);
  //   }
  // }, walletConnected);

  // useEffect(() => {
  //   const fetchSupportedChainInfo = async () => {
  //     try {
  //       const response = await fetch(getSupportedChainsEndpoint());
  //       if (response.ok) {
  //         const data = await response.json();
  //         const connectedChain = data.find(
  //           (chain) => chain.chainId === selectedChainId.toString()
  //         );
  //         setConnectedChainDetails(connectedChain);
  //       } else {
  //         console.error("Failed to fetch Strategy Info");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching Strategy Info:", error);
  //     }
  //   };

  //   fetchSupportedChainInfo();
  // }, [selectedChainId]);

  return (
    <>
      {/* <ChainContext.Provider
        value={{
          selectedChainId,
          isCntToNotSupportedChain,
          connectedChainDetails,
        }}
      >*/}
      <WagmiConfig config={wagmiConfig}>
        <Header
          // setSelectedChainId={setSelectedChainId}
          // setIsCntToNotSupportedChain={setIsCntToNotSupportedChain}
          setWalletConnected={setWalletConnected}
          walletConnected={walletConnected}
        />
        <div>
          <Routes>
            <Route path="/" element={<Trade />}></Route>
            <Route path="/portfolio/*" element={<Portfolio />}></Route>
            <Route path="/strategies" element={<Analyse />}></Route>
            <Route
              path="/strategy"
              element={<StrategyInformation />}
            ></Route>
            <Route path="/trades" element={<Trades />}></Route>
            <Route path="/cheat-sheet" element={<CheatSheet />}></Route>
          </Routes>
        </div>
        <Footer />
      </WagmiConfig>
      <Web3Modal
        themeMode="dark"
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeVariables={{
          "--w3m-overlay-background-color": "rgba(0, 0, 0, 0.8)",
        }}
      />
      {/* </ChainContext.Provider>*/}
    </>
  );
};

export default App;
