import React from "react";
import PropTypes from "prop-types";
import "./input.scss";

const Input = ({ label, value, onChange, placeholder, type }) => {
  return (
    <div className="input-container">
      <label>{label}</label>
      <input
        className="custom-input"
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

export default Input;
