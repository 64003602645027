// sign in wallet with a message
export const signMessage = async (address, message) => {
  try {
    const signature = await window.ethereum.request({
      method: "personal_sign",
      params: [message, address],
    });

    return signature;
  } catch (error) {
    console.error("Error signing message:", error);
  }
};
