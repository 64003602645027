export const EMPTY_STRATEGY = {
  address: null,
  current_price: null,
  // floorType: null,
  floor: null,
  floorValue: null,
  liquidateOnFloor: null, // Boolean- liquidate on Floor Checkbox
  cancelOnFloor: null, // Boolean - Cancel on Floor Checkbox
  // buyType: null,
  buy: null,
  buyValue: null,
  current_price_buy: null, // Boolean - this is tru when user selects current price in Buy policy type dropdown

  buyDCAValue: null,
  buyDCAUnit: null, // unit: fixed or percentage

  btd: null, // Boolean- true when Buy the dip is selected in dropdown
  btdValue: null,
  btdType: null, //"increase_by", // wip: check if this initial value really needed DIP_SPIKE : 'decrease_by','increase_by','fixed_increase','fixed_decrease'

  buyTwap: null, // // Boolean- true when Twap is selected in dropdown
  buyTwapTime: null,
  buyTwapTimeUnit: null, // unit: hours or days

  // sellType: null,
  sell: null,
  sellValue: null,
  current_price_sell: null, // Boolean - this is true when user selects current price in Sell policy type dropdown

  sellDCAValue: null,
  sellDCAUnit: null, // unit: fixed or percentage

  str: null,
  strValue: null,
  strType: null, // "increase_by",

  sellTwap: null,
  sellTwapTime: null,
  sellTwapTimeUnit: null, // unit: hours or days

  completeOnSell: null, // cancel strategy checkbox under sell tab
  // spreadedTime: null,
  // spreadedUnit: null,
  stableToken: null, // token which is selected to buy/sell with the previous token(second dropdown)
  investToken: null, // token which is selected to buy/sell (first dropdown)
  investAmount: null, // budget
  stableAmount: null, // budget
  historical: null,
  historicalStartDate: null,
  historicalEndDate: null,
  highSellValue: null,

  minimumLoss: null,
  minimumProfit: null,

  slippage: null,
};
