import React from "react";
import PropTypes from "prop-types";
import "./Tooltip.scss";
import TooltipIcons from "../../Assets/tooltipIcon.svg";

const Tooltip = ({ text, children }) => {
  return (
    <div className="tooltip-container">
      <div className="tooltip-icon">
        <span>
          <svg width="18" height="18" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.9202 22.4055C18.443 22.4055 22.9202 17.9284 22.9202 12.4055C22.9202 6.88267 18.443 2.40552 12.9202 2.40552C7.39732 2.40552 2.92017 6.88267 2.92017 12.4055C2.92017 17.9284 7.39732 22.4055 12.9202 22.4055Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.9202 16.4055V12.4055" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.9202 8.40552H12.9302" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </span>
      </div>
      <div className="tooltip-content">{children}</div>
    </div>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Tooltip;
