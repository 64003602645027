import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import "./Confirmation.scss";
import ETH from "../../Assets/ETH.svg";
import USDC from "../../Assets/USDC.svg";
import Button from "../Button/Button";
import Back from "../../Assets/back.svg";
import DatePicker from "../DatePicker/Datepicker";
import CalenderIcon from "../../Assets/calender.svg";
import CustomCheckbox from "../Checkbox/CustomCheckbox";
import { EMPTY_STRATEGY } from "../../utils/utils-strategy";
import {
  getCreateStrategyEndpoint,
  getTokenIconEndpoint,
} from "../../api/endpoints";
import { PreviewSummary } from "./PreviewSummary";
import { useAccount } from "wagmi";
import { getcalInvestAmountEndpoint } from "../../api/endpoints";
import { signMessage } from "../../utils/sign-utils";
import { isSameDateInUTC } from "../../utils/format-util";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Confirmation = ({
  onClose,
  formValues,
  investUsdPerToken,
  handleChange,
  setFormValues,
  setShowConfirmation,
  isDCAEnabledForBuy,
  isDCAEnabledForSell,
  setOpenApiSnackbar,
  setApiSnackbarMsg,
  setApiError,
}) => {
  const { address } = useAccount();
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [calInvestAmount, setCalInvestAmount] = useState();

  // const signMessage = async (address, message) => {
  //   try {
  //     const signature = await window.ethereum.request({
  //       method: "personal_sign",
  //       params: [message, address],
  //     });

  //     return signature;
  //   } catch (error) {
  //     console.error("Error signing message:", error);
  //   }
  // };

  const showDate = formValues.historical;
  const handleStartDateChange = (startDate) => {
    console.log(startDate, +"here");
    setSelectedStartDate(startDate);
  };

  const handleEndDateChange = (endDate) => {
    const currentDate = new Date();
    const selectedDate = new Date(endDate);

    if (isSameDateInUTC(currentDate, selectedDate)) {
      // If the date is today, set date + current time
      const currentHour = currentDate.getUTCHours();
      const currentMinute = currentDate.getUTCMinutes();
      const currentSecond = currentDate.getUTCSeconds();
      const newEndDate = new Date(selectedDate);
      newEndDate.setUTCHours(currentHour, currentMinute, currentSecond);
      setSelectedEndDate(newEndDate);
    } else {
      // If the date is not today, set date + 23:59:00
      const newEndDate = new Date(selectedDate);
      newEndDate.setUTCHours(23, 59, 0, 0);
      setSelectedEndDate(newEndDate);
    }
  };

  useEffect(() => {
    handleChange("historicalStartDate", selectedStartDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate]);

  useEffect(() => {
    handleChange("historicalEndDate", selectedEndDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEndDate]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenApiSnackbar(false);
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const signature = await signMessage(
        address,
        `Sign in to create the strategy for ${address}`
      );
      if (signature) {
        // wip: this signature should be verfied by backend in future
        // strategy should be created only after signing the signature
        fetch(getCreateStrategyEndpoint(), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: signature,
          },
          body: JSON.stringify(formValues),
        })
          .then((response) => {
            if (!response.ok) {
              setApiError(true);
            }
            return response.json();
          })
          .then((response, data) => {
            if (response.status === 201) {
              setLoading(false);
              setApiSnackbarMsg(response.message);
              setOpenApiSnackbar(true);
              if (formValues.historical) {
                window.open(`/strategy?id=${response.strategyId}`, "_blank");
              }
              
              if (!formValues.historical) {
                setFormValues({
                  ...EMPTY_STRATEGY,
                  address: address,
                });
                setShowConfirmation(false);
              }
              setApiError(false);
            } else {
              setApiSnackbarMsg(response.message);
              setOpenApiSnackbar(true);
              setLoading(false);
            }
          })
          .catch((error) => {
            console.error("API Error:", error);
            setApiSnackbarMsg("An error occurred while creating the strategy.");
            setOpenApiSnackbar(true);
          });
      } else {
        // the signature is not available here
        setOpenApiSnackbar(true);
        setApiError(true); // to show red snackbar
        setLoading(false);
        setApiSnackbarMsg("Please sign the signature to create strategy");
      }
    } catch (error) {
      // handle errors that might occur during signature
      console.error("Error signing message:", error);
    }
  };

  useEffect(() => {
    const calculateInvestAmount = async () => {
      try {
        const response = await fetch(
          getcalInvestAmountEndpoint(
            formValues.investAmount || formValues.stableAmount,
            formValues.investAmount
              ? formValues.investToken
              : formValues.stableToken
          )
        );
        if (response.ok) {
          const data = await response.json();
          setCalInvestAmount(data.totalAmount);
        } else {
          console.error("Failed to fetch token options");
        }
      } catch (error) {
        console.error("Error fetching token options:", error);
      }
    };

    calculateInvestAmount();
  }, []);

  useEffect(() => {
    if (formValues.historical && formValues.address) {
      if (formValues.historicalEndDate && formValues.historicalStartDate) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [
    formValues.address,
    formValues.historical,
    formValues.historicalEndDate,
    formValues.historicalStartDate,
  ]);

  return (
    <div className="confirm-dialog-card">
      <div className="overlap">
        <div className="dialog-header mobile-no-padding justify-content-start">
          <img
            className="close-icon position-relative me-2"
            src={Back}
            alt="Back"
            onClick={onClose}
          />
          <h4 className="text-start">Confirm strategy</h4>
        </div>
        <div className="dialog-content-body">
          <div className="row">
            <div className="col-lg-6 pe-lg-4 center-line position-relative">
              <div className="token-info">
                <div className="section-with-icon">
                  <div className="left-content">
                    <p className="title">Trade Token</p>
                  </div>
                  <p className="separator">:</p>
                  <div className="right-content">
                    <p className="title">{formValues.investToken}</p>
                    <div className="icon">
                      <img
                        alt="investToken"
                        style={{
                          marginRight: "8px",
                          width: "28px",
                          height: "28px",
                        }}
                        src={getTokenIconEndpoint(formValues.investToken)}
                        onError={(e) => {
                          e.target.src = `https://coinicons-api.vercel.app/api/icon/${formValues.investToken?.toLowerCase()}`;
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="section-with-icon">
                  <div className="left-content">
                    <p className="title">Stable Token</p>
                  </div>
                  <p className="separator">:</p>
                  <div className="right-content">
                    <p className="title">{formValues.stableToken}</p>
                    <div className="icon">
                      <img
                        alt="stableToken"
                        style={{
                          marginRight: "8px",
                          width: "28px",
                          height: "28px",
                        }}
                        src={getTokenIconEndpoint(formValues.stableToken)}
                        onError={(e) => {
                          e.target.src = `https://coinicons-api.vercel.app/api/icon/${formValues.stableToken?.toLowerCase()}`;
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="investment-amount">
                <h4>Investment Amount</h4>
                <div className="investment-amount-info">
                  <p>{`${formValues.investAmount || formValues.stableAmount} ${
                    formValues.investAmount
                      ? formValues.investToken
                      : formValues.stableToken
                  }`}</p>
                  <p>${calInvestAmount}</p>
                </div>
              </div>

              <div className="simulation-date-selection">
                <div className="bg-overlay-simulaiton">
                  <div className="history-check">
                    <CustomCheckbox
                      name="historical"
                      label="Historical Simulation"
                      value={showDate}
                      handleChange={handleChange}
                      formValues={formValues}
                    />
                  </div>

                  {showDate && (
                    <div className="type-value padding-0 mt-2">
                      <div className="mt-3 date-div">
                        <DatePicker
                          selectedDate={selectedStartDate}
                          onChange={handleStartDateChange}
                          label="Select Date"
                          formValues={formValues}
                          title="From"
                        />
                      </div>
                      <div className="mt-3 date-div">
                        <DatePicker
                          selectedDate={selectedEndDate}
                          onChange={handleEndDateChange}
                          formValues={formValues}
                          label="Select a date"
                          title="To"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6 ps-lg-4">
              <PreviewSummary
                formValues={formValues}
                isDCAEnabledForBuy={isDCAEnabledForBuy}
                isDCAEnabledForSell={isDCAEnabledForSell}
              />
              <div className="confirm-btn m-b-20">
                <LoadingButton
                  label="Confirm"
                  className="custom-button"
                  onClick={handleConfirm}
                  loading={loading}
                  disabled={formValues.address ? false : true} // wip: check historical
                >
                  {formValues.address
                    ? loading
                      ? formValues.historical
                        ? ""
                        : ""
                      : "Confirm"
                    : "Connect Wallet"}
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
