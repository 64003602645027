import React from "react";
import "./Tab.scss";

const Tab = ({ options, selectedOption, onSelect }) => {
  return (
    <>
      {/* <label htmlFor="amount" className='p-0'>Amount:</label> */}
      <div className="tab-switch">
        {options.map((option) => (
          <div
            key={option.value}
            className={`tab-option ${option.value === selectedOption ? "selected" : ""
              }`}
            onClick={() => {
              onSelect(option.value);
            }}
          >
            <div className="d-flex align-items-center justify-content-md-start justify-content-center">
              <img src={`../../Assets/${option.img}`} alt="Icon" className="me-1" />
              {option.label}
            </div>
            <p className="d-lg-block d-none">{option.desc}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default Tab;
