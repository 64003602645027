import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./AnalyseCard.scss";
import {
  getStrategiesByAddressEndpoint,
  getFilteredStrtegiesByTokenEndpoint,
  getFilteredStrtegiesByStatusEndpoint,
  getFilteredStrtegiesByTokenAndStatusEndpoint,
  getTokenIconEndpoint,
} from "../../api/endpoints";
import { useAccount } from "wagmi";
import { formatDate, capitalizeFirstLetter } from "../../utils/format-util";
import EmptyIcon from "../../Assets/empty-portfolio.svg";
import { CircularProgress } from "@mui/material";

const AnalyseCard = ({
  selectedStatus,
  selectedFilterToken,
  filteredAddress,
  currentPage,
  setTotalPages,
  setStrategies,
  strategies,
}) => {
  const { address } = useAccount();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStrategies = async () => {
      setIsLoading(true); // Set loading to true while fetching
      setError(null); // Clear any previous errors
      try {
        let endpoint;

        // use specific endpoint depending on the filters
        if (filteredAddress) {
          if (selectedFilterToken && selectedStatus) {
            endpoint = getFilteredStrtegiesByTokenAndStatusEndpoint(
              filteredAddress,
              selectedFilterToken,
              selectedStatus
            );
          } else if (selectedFilterToken) {
            endpoint = getFilteredStrtegiesByTokenEndpoint(
              filteredAddress,
              selectedFilterToken
            );
          } else if (selectedStatus) {
            endpoint = getFilteredStrtegiesByStatusEndpoint(
              filteredAddress,
              selectedStatus
            );
          } else
            endpoint = getStrategiesByAddressEndpoint(
              filteredAddress,
              currentPage
            );
        } else {
          if (selectedFilterToken && selectedStatus) {
            endpoint = getFilteredStrtegiesByTokenAndStatusEndpoint(
              address,
              selectedFilterToken,
              selectedStatus
            );
          } else if (selectedFilterToken) {
            endpoint = getFilteredStrtegiesByTokenEndpoint(
              address,
              selectedFilterToken
            );
          } else if (selectedStatus) {
            endpoint = getFilteredStrtegiesByStatusEndpoint(
              address,
              selectedStatus
            );
          } else {
            endpoint = getStrategiesByAddressEndpoint(address, currentPage);
          }
        }
        const response = await fetch(endpoint);

        if (response.ok) {
          const data = await response.json();
          setStrategies(data);
          setTotalPages(data.totalPages);

          // Fetch token symbols for each strategy
          // const updatedStrategies = await Promise.all(
          //   data.data.map(async (item) => {
          //     const investTokenSymbol = await getTokenSymbol(item.investToken);
          //     const stableTokenSymbol = await getTokenSymbol(item.stableToken);

          //     return {
          //       ...item,
          //       investTokenSymbol,
          //       stableTokenSymbol,
          //     };
          //   })
          // );

          setStrategies(data);
        } else {
          setError("Failed to fetch strategies");
          setStrategies({});
        }
      } catch (error) {
        setError("Error fetching strategies: " + error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchStrategies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    address,
    selectedStatus,
    filteredAddress,
    selectedFilterToken,
    currentPage,
  ]);

  if (isLoading) {
    return (
      <div className="text-center w-100 py-5">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {strategies.strArr?.length > 0 ? (
        strategies.strArr?.map((item, key) => (
          <div className="analyse-card-outer mt-4" key={key.id}>
            <div className="analyse-container">
              <div className="card-header d-flex align-items-center justify-content-between">
                <div
                  className={
                    "live-updates d-flex align-items-center " +
                    ` ${item.status}`
                  }
                >
                  <span className="live-dot"></span>
                  <p className="ms-4 mb-0">
                    {capitalizeFirstLetter(item.status)}
                  </p>
                </div>
                <NavLink
                  to={`/strategy?id=${item.id}`}
                  className="anchor-link d-flex align-items-center justify-content-center"
                  target="_blank"
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5 20.5833L20.5833 6.5M20.5833 6.5V20.02M20.5833 6.5H7.06333"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </NavLink>
              </div>
              <div className="card-body-content mt-4">
                <h2>
                  ${item.totalValue}{" "}
                  <span
                    className={
                      item.profitLossPercentage.startsWith("-")
                        ? "down-arrow"
                        : "up-arrow"
                    }
                  >
                    {" "}
                    {/*wip: "down-arrow"/up-arrow change it according to the response */}
                    <i className=""></i>
                    {item?.profitLossAmountUSD +
                      "(" +
                      item?.profitLossPercentage?.replace("$", "") +
                      "%)" +
                      ""}
                    {/* -35.48 (-1.95%)*/}
                  </span>
                </h2>
                <ul className="analyse-inner-listing ps-0 mb-0">
                  <li>
                    <div className="d-flex align-items-center justify-content-between">
                      <p>Tokens</p>
                      <div className="token-list d-flex align-items-center">
                        <h3>
                          <img
                            src={getTokenIconEndpoint(item?.investToken)}
                            onError={(e) => {
                              e.target.src = `https://coinicons-api.vercel.app/api/icon/${item?.investToken?.toLowerCase()}`;
                            }}
                          />
                          {item.investToken}
                        </h3>
                        <h3>
                          <img
                            src={getTokenIconEndpoint(item?.stableToken)}
                            onError={(e) => {
                              e.target.src = `https://coinicons-api.vercel.app/api/icon/${item?.stableToken?.toLowerCase()}`;
                            }}
                          />
                          {item.stableToken}
                        </h3>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center justify-content-between">
                      <p>Average Buy Price</p>
                      <h3>
                        {item.investPrice
                          ? item.investPrice + " " + item.stableToken
                          : "-"}
                      </h3>
                    </div>
                  </li>

                  <li>
                    <div className="d-flex align-items-center justify-content-between">
                      <p>Created At</p>
                      <h3>{formatDate(item.createdAt)}</h3>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center justify-content-between">
                      <p>Total Budget</p>
                      <h3>
                        {item.budget} {item.stableToken}
                      </h3>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="wallet-listing d-flex flex-wrap mx-auto">
          <div className="d-flex h-100 align-items-center justify-content-center flex-column w-100 empty-div-portfolio">
            <img src={EmptyIcon} alt="Icon" />
            <h3 className="text-white">
              {!address && "Connect Wallet"}

              {address && "No Strategies Found"}
            </h3>
            <p>
              {!address &&
                "Connect to a wallet to see your strategies or search for strategies by an address"}

              {address &&
                "No strategy was found for the selected filters. Please try again with different filters or create strategies."}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default AnalyseCard;
