import {
  formatNumberToCurrency,
  addCommasToNumber,
} from "../../utils/format-util";
import { getTokenIconEndpoint } from "../../api/endpoints";

const CheatCard = ({ selectedToken, cheatsheetDetails }) => {
  if (!cheatsheetDetails) return;
  return (
    <>
      <div className="analyse-card-outer mw-100 w-100 cheet-outer-container">
        <div className="analyse-container">
          <div className="card-header">
            <h2 className="d-flex align-items-center">
              <img
                src={getTokenIconEndpoint(selectedToken?.symbol)}
                onError={(e) => {
                  e.target.src = `https://coinicons-api.vercel.app/api/icon/${selectedToken?.symbol.toLowerCase()}`;
                }}
                alt="Token Icon"
                className="me-2"
              />
              {selectedToken?.name}
            </h2>
          </div>
          <div className="card-body-content mt-4">
            <h2>
              $ {addCommasToNumber(cheatsheetDetails.price)}
              <span
                className={
                  cheatsheetDetails.priceChange.startsWith("-")
                    ? "down-arrow"
                    : "up-arrow"
                }
              >
                <i className=""></i>
                {addCommasToNumber(cheatsheetDetails.priceChangeInUSD)} (
                {cheatsheetDetails.priceChange})
              </span>
            </h2>
            <ul className="analyse-inner-listing ps-0 mb-0">
              <li>
                <div className="d-flex align-items-center justify-content-between">
                  <p>Market cap</p>
                  <h3>
                    <span
                      className={
                        cheatsheetDetails.marketCapChange.startsWith("-")
                          ? "down-arrow"
                          : "up-arrow"
                      }
                    >
                      <i className=""></i>
                      {cheatsheetDetails.marketCapChange}
                    </span>
                    $ {formatNumberToCurrency(cheatsheetDetails.marketCap)}
                  </h3>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center justify-content-between">
                  <p>Volume</p>
                  <h3>
                    <span
                      className={
                        cheatsheetDetails.volumeChangeInPer.startsWith("-")
                          ? "down-arrow"
                          : "up-arrow"
                      }
                    >
                      <i className=""></i>
                      {cheatsheetDetails.volumeChangeInPer}
                    </span>
                    $ {formatNumberToCurrency(cheatsheetDetails.volume)}
                  </h3>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center justify-content-between">
                  <p>Summary</p>
                  <h3>
                    {cheatsheetDetails.summary
                      ? cheatsheetDetails.summary
                      : "-"}
                  </h3>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheatCard;
