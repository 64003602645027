import React, { useEffect, useState } from "react";
import "./ModalInputUnit.scss";
import { usePreventMouseWheel } from "../../utils/input-util";

const AmountInputWithUnit = ({
  name,
  stableToken,
  formValues,
  selectedTab,
  onAmountChange,
}) => {
  const [amount, setAmount] = useState(formValues[name]);
  const [showPercentage, setShowPercentage] = useState(false);
  const [showNoDecimal, setShowNoDecimal] = useState(false);

  const inputRef = React.createRef();
  usePreventMouseWheel(inputRef);

  const handleAmountChange = (e) => {
    const newAmount = e.target.value;
    setAmount(newAmount);
    onAmountChange(name, newAmount);
  };

  useEffect(() => {
    setAmount(formValues[name]);
  }, [formValues, name]);

  useEffect(() => {
    // Determine whether to show the stable token symbol or percentage sign
    selectedTab &&
      setShowPercentage(
        (selectedTab === "Buy" &&
          (formValues.buy === "increase_by" ||
            formValues.buy === "decrease_by")) ||
          (selectedTab === "Sell" &&
            (formValues.sell === "increase_by" ||
              formValues.sell === "decrease_by")) ||
          (selectedTab === "Floor" &&
            (formValues.floor === "increase_by" ||
              formValues.floor === "decrease_by"))
      );

    setShowNoDecimal(name === "minimumLoss" || name === "minimumProfit");
  }, [formValues, selectedTab]);

  return (
    <div className="amount-input-unit">
      <div className="input-container">
        <input
          ref={inputRef}
          type="number"
          placeholder={showPercentage || showNoDecimal ? "0" : "0.00"}
          value={parseFloat(amount)}
          onChange={handleAmountChange}
          inputMode="decimal"
          min="0"
          onKeyDown={(e) => {
            // prevent input of minus key
            if (e.key === "-" || e.key === "+") {
              e.preventDefault();
            }
          }}
        />
        <div className="unit-box">
          <div>{showPercentage ? "%" : stableToken}</div>{" "}
          {/*// wip: does backend really cares about whether it is a token or percentage? */}
        </div>
      </div>
    </div>
  );
};

export default AmountInputWithUnit;
