import AnalyseCard from "../../components/AnalyseCard/AnalyseCard";
import PaginationItem from "../../components/Pagination/Pagination";
import StrategyFilter from "../../components/StrategyFilter/StrategyFilter";
import "../Portfolio/portfolio.scss";
import { useState } from "react";

const Analyse = () => {
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedFilterToken, setSelectedFilterToken] = useState(null);
  const [filteredAddress, setFilteredAddress] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [strategies, setStrategies] = useState([]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="overlap-group">
        <div className=" wallet-container-outer pt-0">
          <div className="wallet-header text-center d-md-flex justify-content-between align-items-center">
            <h1 className="text-white mb-md-0 mb-3">Strategies</h1>
          </div>
          <StrategyFilter
            onSelectStatus={setSelectedStatus}
            onSelectFilterToken={setSelectedFilterToken}
            onAddressEntered={setFilteredAddress}
          />
          <div className="analyse-listing d-flex flex-wrap">
            <AnalyseCard
              selectedStatus={selectedStatus}
              selectedFilterToken={selectedFilterToken}
              filteredAddress={filteredAddress}
              currentPage={currentPage}
              setTotalPages={setTotalPages}
              setStrategies={setStrategies}
              strategies={strategies}
            />
          </div>
          {strategies.strArr?.length > 0 && (
            <div className="d-flex align-items-center justify-content-center mt-4">
              <PaginationItem
                onPageChange={handlePageChange}
                totalPages={totalPages}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Analyse;
