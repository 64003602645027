import SemiCircleProgressBar from "react-progressbar-semicircle";
const ProgressBar = ({ cheatsheetDetails }) => {
  if (!cheatsheetDetails) return;

  return (
    <>
      <div className="progress-outer h-100 mt-md-0 mt-4">
        <div className="bg-container h-100 position-relative">
          <div className="bg-header text-center">
            <h2>Fear & Greed Index</h2>
          </div>
          <SemiCircleProgressBar
            percentage={cheatsheetDetails.fearAndGreedValue}
            stroke="#FFBA4E"
            strokeWidth="30"
            diameter="300"
            background="#F5F5F5"
            className="mx-auto"
          />
          <span className="text-center value-text">{cheatsheetDetails.fearAndGreedValue}</span>
          <span className="text-center d-block">
            {cheatsheetDetails.fearAndGreedType}
          </span>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
