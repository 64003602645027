import "./Confirmation.scss";

export const PreviewSummary = ({
  formValues,
  isDCAEnabledForBuy,
  isDCAEnabledForSell,
}) => {
  return (
    <>
      <div className="investment-amount border-0">
        <h4>Summary :</h4>
        {formValues.floor && (
          <>
            <div className="investment-amount-info">
              <p>
                Floor {formValues.floor === "limit_price" ? `Limit Price` : ""}
              </p>
              <p>
                {formValues.floor === "limit_price"
                  ? `${formValues.floorValue} ${formValues.stableToken}`
                  : ""}
                {formValues.floor === "decrease_by"
                  ? `${formValues.floorValue}% Loss`
                  : ""}
              </p>
            </div>
          </>
        )}
        {formValues.floorValue && formValues.buy && (
          <>
            <div className="investment-amount-info">
              <p>Cancel at Floor</p>
              <p>{formValues.cancelOnFloor === true ? `Yes` : "No"}</p>
            </div>
          </>
        )}
        {formValues.floorValue && (
          <>
            <div className="investment-amount-info">
              <p>Liquidate at Floor</p>
              <p>{formValues.liquidateOnFloor === true ? `Yes` : "No"}</p>
            </div>
          </>
        )}

        {formValues.floor && formValues.minimumLoss && (
          <>
            <div className="investment-amount-info">
              <p>Minimum Loss Threshold</p>
              <p>
                {formValues.minimumLoss} {formValues.stableToken}
              </p>
            </div>
          </>
        )}

        {formValues.buy && (
          <>
            <div className="investment-amount-info">
              <p>
                Buy {formValues.buy !== "current_price" ? `Limit Price` : ""}
              </p>
              <p>
                {formValues.buy === "limit_price"
                  ? `${formValues.buyValue} ${formValues.stableToken}`
                  : ""}

                {formValues.buy === "current_price"
                  ? `Current Market Price`
                  : ""}
              </p>
            </div>
          </>
        )}
        {isDCAEnabledForBuy && (
          <>
            <div className="investment-amount-info">
              <p>Dollar Cost Averaging</p>
              <p>Enabled</p>
            </div>
          </>
        )}
        {formValues.buyDCAValue && (
          <>
            <div className="investment-amount-info">
              <p>Buy Spread Amount</p>
              <p>
                {formValues.buyDCAValue}{" "}
                {formValues.buyDCAUnit === "fixed"
                  ? formValues.stableToken
                  : "%"}
              </p>
            </div>
          </>
        )}
        {formValues.buyTwap && (
          <>
            <div className="investment-amount-info">
              <p>Buy Every</p>
              <p>
                {formValues.buyTwapTime} {formValues.buyTwapTimeUnit}
              </p>
            </div>
          </>
        )}
        {formValues.btd && (
          <>
            <div className="investment-amount-info">
              <p>Buy Every</p>
              <p>
                {formValues.btdValue}
                {formValues.btdType === "increase_by" ? "% Spike" : ""}
                {formValues.btdType === "decrease_by" ? "% Dip" : ""}
                {formValues.btdType === "fixed_increase"
                  ? " " + formValues.stableToken + " Spike"
                  : ""}
                {formValues.btdType === "fixed_decrease"
                  ? " " + formValues.stableToken + " Dip"
                  : ""}
              </p>
            </div>
          </>
        )}

        {formValues.sell && (
          <>
            <div className="investment-amount-info">
              <p>
                Sell {formValues.sell === "limit_price" ? `Limit Price` : ""}
              </p>
              <p>
                {formValues.sell === "limit_price"
                  ? `${formValues.sellValue} ${formValues.stableToken}`
                  : ""}

                {formValues.sell === "current_price"
                  ? `Current Market Price`
                  : ""}

                {formValues.sell === "increase_by"
                  ? `${formValues.sellValue}% Profit`
                  : ""}
              </p>
            </div>
          </>
        )}

        {formValues.sell && formValues.highSellValue && (
          <>
            <div className="investment-amount-info">
              <p>Sell Max Limit Price</p>
              <p>
                {formValues.highSellValue} {formValues.stableToken}
              </p>
            </div>
          </>
        )}

        {isDCAEnabledForSell && (
          <>
            <div className="investment-amount-info">
              <p>Spread Sell Orders</p>
              <p>Enabled</p>
            </div>
          </>
        )}
        {formValues.sellDCAValue && (
          <>
            <div className="investment-amount-info">
              <p>Sell Spread Amount</p>
              <p>
                {formValues.sellDCAValue}{" "}
                {formValues.sellDCAUnit === "fixed"
                  ? formValues.stableToken
                  : "%"}
              </p>
            </div>
          </>
        )}
        {formValues.sellTwap && (
          <>
            <div className="investment-amount-info">
              <p>Sell Every</p>
              <p>
                {formValues.sellTwapTime} {formValues.sellTwapTimeUnit}
              </p>
            </div>
          </>
        )}
        {formValues.str && (
          <>
            <div className="investment-amount-info">
              <p>Sell Every</p>
              <p>
                {formValues.strValue}
                {formValues.strType === "increase_by" ? "% Spike" : ""}
                {formValues.strType === "decrease_by" ? "% Dip" : ""}
                {formValues.strType === "fixed_increase"
                  ? " " + formValues.stableToken + " Spike"
                  : ""}
                {formValues.strType === "fixed_decrease"
                  ? " " + formValues.stableToken + " Dip"
                  : ""}
              </p>
            </div>
          </>
        )}

        {formValues.sell && formValues.buy && (
          <>
            <div className="investment-amount-info">
              <p>Recurring</p>
              <p>{formValues.completeOnSell === true ? `No` : "Yes"}</p>
            </div>
          </>
        )}

        {formValues.sell && formValues.minimumProfit && (
          <>
            <div className="investment-amount-info">
              <p>Minimum Profit Threshold</p>
              <p>
                {formValues.minimumProfit} {formValues.stableToken}
              </p>
            </div>
          </>
        )}

        {/* // wip: Should extract as a seperate component if this summary keeps on extending*/}
      </div>
    </>
  );
};
