import { useEffect } from "react";

// Custom hook to handle the mousewheel event behavior
export const usePreventMouseWheel = (inputRef) => {
  useEffect(() => {
    const handleMouseWheel = (e) => {
      e.preventDefault();
    };

    const inputElement = inputRef?.current;
    inputElement?.addEventListener("mousewheel", handleMouseWheel);

    return () => {
      inputElement?.removeEventListener("mousewheel", handleMouseWheel);
    };
  }, [inputRef]);
};
