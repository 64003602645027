import React from "react";
import PropTypes from "prop-types";
import "./ToggleSwitch.scss";

const ToggleSwitch = ({ options, selectedOption, onSelect }) => {
  return (
    <div className="toggle-switch">
      {options.map((option, index) => (
        <div
          key={index}
          className={`toggle-option ${
            option?.symbol === selectedOption ? "selected" : ""
          }`}
          onClick={() => onSelect(option?.symbol)}
        >
          {option?.symbol}
        </div>
      ))}
    </div>
  );
};

ToggleSwitch.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOption: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ToggleSwitch;
