import React, { useState, useEffect, useContext, useRef } from "react";
import "./trade.scss";
import Row from "react-bootstrap/Row";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingIcon from "../../Assets/setting.svg";
import AmountInputWithToggle from "../../components/Selectinput/AmountInputWithToggle";
import AmountInputWithUnit from "../../components/Selectinput/AmountInputWithUnit";
import Tab from "../../components/Tabs/Tabs";
import DropdownSelect from "../../components/DropdownSelect/DropdownSelect";
import CustomCheckbox from "../../components/Checkbox/CustomCheckbox";
import Inputwithtoggle from "../../components/InputwithToggle/Inputwithtoggle";
import Button from "../../components/Button/Button";
import SelectWithDialog from "../../components/SelectWithDialog/SelectWithDialog";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { getTokensEndpoint, getTokenPriceEndpoint } from "../../api/endpoints";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Confirmation from "../../components/ConfirmationScreen/Confirmation";
import SlippageFees from "../../components/SlippageFees/SlippageFees";
import { useAccount, useSwitchNetwork } from "wagmi";
import { EMPTY_STRATEGY } from "../../utils/utils-strategy";
import { ToggleOptions } from "./ToggleOptions";
import CancelStrategyIcon from "../../components/Assets/cancel-strategy.svg";
import LiquidationIcon from "../../components/Assets/liquidation.svg";
import MaxSellIcon from "../../components/Assets/max-sell-icon.svg";
import DCAIcon from "../../components/Assets/dca-icon.svg";
import CustomSnackbar from "../../components/CustomSnackbar";
import Tooltip from "../../components/Tooltips/Tooltip";
// import { ChainContext } from "../../App";
import EmptyIcon from "../../Assets/empty-portfolio.svg";

// import "./SlippageFees.scss";

const buyOptions = [
  { label: "No Configuration", value: "no_configuration" },
  { label: "Limit Price", value: "limit_price" },
  { label: "Current Price", value: "current_price" },
  // { label: "Increase By", value: "increase_by" },
  // { label: "Decrease By", value: "decrease_by" },
];

const buyOptionsWithoutCurrent = [
  { label: "No Configuration", value: "no_configuration" },
  { label: "Limit Price", value: "limit_price" },
  // { label: "Current Price", value: "current_price" },
];

const sellOptions = [
  { label: "No Configuration", value: "no_configuration" },
  { label: "Limit Price", value: "limit_price" },
  { label: "Current Price", value: "current_price" },
  { label: "Take Profit Percentage", value: "increase_by" },
];

const sellOptionsWithoutCurrent = [
  { label: "No Configuration", value: "no_configuration" },
  { label: "Limit Price", value: "limit_price" },
  // { label: "Current Price", value: "current_price" },
  { label: "Take Profit Percentage", value: "increase_by" },
];

const floorOptions = [
  { label: "No Configuration", value: "no_configuration" },
  { label: "Limit Price", value: "limit_price" },
  { label: "Stop Loss Percentage", value: "decrease_by" },
];

const buyDcaOptions = [
  // { label: "No Configuration", value: "no_configuration" },
  { label: "Buy the Dip or Recovery", value: "buy_the_dip" },
  { label: "TWAP", value: "twap" },
];

const sellDcaOptions = [
  // { label: "No Configuration", value: "no_configuration" },
  { label: "Sell the Rally or Trailing Stop Loss", value: "sell_the_rally" },
  { label: "TWAP", value: "twap" },
];
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Trade = (ref) => {
  const { address } = useAccount();
  // const { selectedChainId, isCntToNotSupportedChain, connectedChainDetails } =
  //   useContext(ChainContext);
  const selectedChainId = "324";
  const isCntToNotSupportedChain = false;
  const connectedChainDetails = {};
  const { switchNetwork } = useSwitchNetwork();

  const [selectedTab, setSelectedTab] = useState("Buy");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [tokenOptions, setTokenOptions] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false); // State to manage visibility of Confirmation
  const [investUSD, setInvestUSD] = useState(0);
  const [investStable, setInvestStable] = useState(0);
  const [formValues, setFormValues] = useState({
    ...EMPTY_STRATEGY,
    address: address,
  });
  const [showSlippage, setShowSlippage] = useState(false);
  const [customSlippage, setCustomSlippage] = useState();
  const [selectedBuySellToken, setSelectedBuySellToken] = useState(); // token which is selected to buy/sell (first dropdown)
  const [selectedWithToken, setSelectedWithToken] = useState(); // token which is selected to buy/sell with the previous token(second dropdown)
  const [investAmount, setInvestAmount] = useState(formValues.investAmount);
  const [stableAmount, setStableAmount] = useState(formValues.stableAmount);
  const [budgetCurrency, setBudgetCurrency] = useState(formValues.stableToken);
  const [isDCAEnabledForBuy, setIsDCAEnabledForBuy] = useState();
  const [isDCAEnabledForSell, setIsDCAEnabledForSell] = useState();
  const [isSellMaxLimitEnabled, setIsSellMaxLimitEnabled] = useState();
  const [isSellMinimumProfitEnabled, setIsSellMinimumProfitEnabled] =
    useState();
  const [isFloorMinimumLossEnabled, setIsFloorMinimumLossEnabled] = useState();
  const [selectedBuyItem, setSelectedBuyItem] = useState("+%");
  const [selectedSellItem, setSelectedSellItem] = useState("+%");
  const [isTokensLoading, setIsTokensLoading] = useState(true); // Added isLoading state
  const [openCustomSnackbar, setOpenCustomSnackbar] = useState(false);
  const [openApiSnackbar, setOpenApiSnackbar] = useState(false);
  const [customSnackbarMsg, setCustomSnackbarMsg] = useState("");
  const [apiSnackbarMsg, setApiSnackbarMsg] = useState("");
  const [apiError, setApiError] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const [selectedInvestTokenDetails, setSelectedInvestTokenDetails] =
    useState(""); // this hold all details like symbol,address,decimal etc...
  const [selectedStableTokenDetails, setSelectedStableTokenDetails] =
    useState("");

  // refer https://github.com/Fyda-Finance/backend/blob/main/src/strategy/dto/createStrategy.dto.ts
  const handleSelect = (option) => {};

  const usdCurrencyOptions = [
    // values are feeded to backend
    {
      name: selectedWithToken?.name,
      symbol: selectedWithToken?.symbol,
      value: "fixed",
    },
    { name: "%", symbol: "%", value: "percentage" },
  ];

  const investCurrencyOptions = [
    {
      name: selectedBuySellToken?.name,
      symbol: selectedBuySellToken?.symbol,
      value: "fixed",
    },
    {
      name: "%",
      symbol: "%",
      value: "percentage",
    },
  ];

  const frequencyOptions = [
    { name: "Days", symbol: "Days", value: "days" },
    { name: "Hours", symbol: "Hours", value: "hours" },
  ];

  const tabOptions = [
    {
      label: "Floor",
      value: "Floor",
      img: "floor.svg",
      desc: "Implement Stop-Loss Protection",
    },
    {
      label: "Buy",
      value: "Buy",
      img: "buy.svg",
      desc: "Define Key Entry Points for Investment",
    },
    {
      label: "Sell",
      value: "Sell",
      img: "sell.svg",
      desc: "Establish Targets for Taking Profits",
    },
  ];
  const handleChange = (name, value) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  const handleBuySellTokenSelect = (selectedToken) => {
    handleChange("investToken", selectedToken.symbol);
    setSelectedBuySellToken(selectedToken);
  };
  const handleWithTokenSelect = (selectedToken) => {
    handleChange("stableToken", selectedToken.symbol);
    setSelectedWithToken(selectedToken);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleShowConfirmation = () => {
    if (
      (formValues.stableAmount === "0" || !formValues.stableAmount) &&
      (formValues.investAmount === "0" || !formValues.investAmount)
    ) {
      setOpenCustomSnackbar(true);
      setCustomSnackbarMsg("Enter a valid budget to continue");
      return;
    }

    if (!formValues.buy && !formValues.floor && !formValues.sell) {
      setOpenCustomSnackbar(true);
      setCustomSnackbarMsg("Add atleast one configutation to continue");
      return;
    }

    if (
      formValues.floor === "limit_price" &&
      (!formValues.floorValue || formValues.floorValue === "0")
    ) {
      setOpenCustomSnackbar(true);
      setCustomSnackbarMsg("Enter a valid floor price to continue");
      return;
    }

    if (
      formValues.floor === "decrease_by" &&
      (!formValues.floorValue || formValues.floorValue === "0")
    ) {
      setOpenCustomSnackbar(true);
      setCustomSnackbarMsg("Enter a valid floor loss percentage to continue");
      return;
    }

    if (
      formValues.buy === "limit_price" &&
      (!formValues.buyValue || formValues.buyValue === "0")
    ) {
      setOpenCustomSnackbar(true);
      setCustomSnackbarMsg("Enter a valid buy price to continue");
      return;
    }

    if (isDCAEnabledForBuy) {
      if (!formValues.buyDCAValue || formValues.buyDCAValue === "0") {
        setOpenCustomSnackbar(true);
        setCustomSnackbarMsg("Enter a valid buy spread amount to continue");
        return;
      }

      if (!formValues.btd && !formValues.buyTwap) {
        setOpenCustomSnackbar(true);
        setCustomSnackbarMsg("Select any buy DCA type to continue");
        return;
      }

      if (
        formValues.btd &&
        (!formValues.btdValue || formValues.btdValue === "0")
      ) {
        setOpenCustomSnackbar(true);
        setCustomSnackbarMsg("Enter a valid Buy the Dip Amount to continue");
        return;
      }

      if (
        formValues.buyTwap &&
        (!formValues.buyTwapTime || formValues.buyTwapTimeUnit === null)
      ) {
        setOpenCustomSnackbar(true);
        setCustomSnackbarMsg("Enter a valid buy TWAP interval to continue");
        return;
      }
    }

    if (
      formValues.sell === "increase_by" &&
      (!formValues.sellValue || formValues.sellValue === "0")
    ) {
      setOpenCustomSnackbar(true);
      setCustomSnackbarMsg("Enter a valid sell profit percentage to continue");
      return;
    }

    if (isFloorMinimumLossEnabled && formValues.minimumLoss === "0") {
      setOpenCustomSnackbar(true);
      setCustomSnackbarMsg("Enter a valid minimum loss to continue");
      return;
    }

    if (isSellMinimumProfitEnabled && formValues.minimumProfit === "0") {
      setOpenCustomSnackbar(true);
      setCustomSnackbarMsg("Enter a valid minimum profit to continue");
      return;
    }

    if (
      formValues.sell === "limit_price" &&
      (!formValues.sellValue || formValues.sellValue === "0")
    ) {
      setOpenCustomSnackbar(true);
      setCustomSnackbarMsg("Enter a valid sell price to continue");
      return;
    }

    if (isDCAEnabledForSell) {
      if (!formValues.sellDCAValue || formValues.sellDCAValue === "0") {
        setOpenCustomSnackbar(true);
        setCustomSnackbarMsg("Enter a valid sell spread amount to continue");
        return;
      }

      if (!formValues.str && !formValues.sellTwap) {
        setOpenCustomSnackbar(true);
        setCustomSnackbarMsg("Select any sell DCA type to continue");
        return;
      }

      if (
        formValues.str &&
        (!formValues.strValue || formValues.strValue === "0")
      ) {
        setOpenCustomSnackbar(true);
        setCustomSnackbarMsg("Enter a valid Sell the Dip Amount to continue");
        return;
      }

      if (
        formValues.sellTwap &&
        (!formValues.sellTwapTime || formValues.sellTwapTimeUnit === null)
      ) {
        setOpenCustomSnackbar(true);
        setCustomSnackbarMsg("Enter a valid sell TWAP interval to continue");
        return;
      }
    }

    if (formValues.investAmount === null && formValues.buy === null) {
      setOpenCustomSnackbar(true);
      setCustomSnackbarMsg("Enter a valid buy configuration to continue");
      return;
    }

    if (
      formValues.stableAmount === null &&
      formValues.sell === null &&
      formValues.liquidateOnFloor === null
    ) {
      setOpenCustomSnackbar(true);
      setCustomSnackbarMsg(
        "Enter a valid sell configuration or enable liquidation floor to continue"
      );
      return;
    }
    setShowConfirmation(true);
  };

  const handleBackFromConfirmation = () => {
    setShowConfirmation(false);
  };

  let previousSelectedChainId;

  useEffect(() => {
    // reset state
    setInvestAmount(null);
    setStableAmount(null);
    setSelectedBuySellToken();
    setSelectedWithToken();
    setBudgetCurrency(formValues.stableToken);
    setTokenOptions([]);
    setShowConfirmation(false);
    setInvestUSD(0);
    setInvestStable(0);
    setFormValues({
      ...EMPTY_STRATEGY,
      address: address,
    });
    setShowSlippage(false);
    setCustomSlippage();
    setIsDCAEnabledForBuy();
    setIsDCAEnabledForSell();
    setIsSellMaxLimitEnabled();
    setIsFloorMinimumLossEnabled();
    setSelectedBuyItem("+%");
    setSelectedSellItem("+%");
    setIsTokensLoading(true);
    setOpenCustomSnackbar(false);
    setOpenApiSnackbar(false);
    setCustomSnackbarMsg("");
    setApiSnackbarMsg("");
    setApiError(false);
    setShowPopup(true);
    setSelectedInvestTokenDetails("");
    setSelectedStableTokenDetails("");

    const fetchTokenOptions = async () => {
      try {
        if (selectedChainId) {
          setIsTokensLoading(true);
          const response = await fetch(getTokensEndpoint(selectedChainId));

          if (response.ok) {
            const data = await response.json();
            setTokenOptions(data);
            setIsTokensLoading(false);
          } else {
            console.error("Failed to fetch token options");
          }
        }
      } catch (error) {
        console.error("Error fetching token options:", error);
        setIsTokensLoading(true);
      }
    };

    fetchTokenOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChainId]);

  useEffect(() => {
    handleChange("address", address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const handleClose = (event, reason) => {
    // close snackbar
    if (reason === "clickaway") {
      return;
    }
    setOpenCustomSnackbar(false);
    setOpenApiSnackbar(false);
  };

  const handleOpenSlippage = () => {
    setShowSlippage(true);
  };

  const handleBackFromSlippage = () => {
    setShowSlippage(false);
  };

  const onBack = () => {
    setShowSlippage(false);
  };
  useEffect(() => {
    if (!formValues.investToken || !formValues.stableToken) return;

    // create an AbortController instance To manage fetch cancellation
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchTokenPrice = async () => {
      try {
        const response = await fetch(
          getTokenPriceEndpoint(
            selectedInvestTokenDetails.symbol,
            selectedStableTokenDetails.symbol,
            selectedChainId
          ),
          { signal } // pass the signal to the fetch request
        );

        if (response.ok) {
          const data = await response.json();
          setInvestUSD(data.investUSD);
          setInvestStable(data.investStable);
        } else {
          console.error("Failed to fetch token options");
        }
      } catch (error) {
        console.error("Error fetching token options:", error);
      }
    };

    // call fetchTokenPrice only if the component is still mounted
    if (!signal.aborted) {
      fetchTokenPrice();
    }

    // cleanup function to abort the fetch if the component unmounts or if a new request is made
    return () => abortController.abort();
  }, [
    formValues.investToken,
    formValues.stableToken,
    selectedChainId,
    selectedStableTokenDetails,
    selectedInvestTokenDetails,
  ]);

  useEffect(() => {
    // updating these two values with seperate usestate variables as react doesn't update 2 states immediately, expecially when both are depended on one another
    setFormValues({
      ...formValues,
      investAmount: investAmount,
      stableAmount: stableAmount,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investAmount, stableAmount]);

  const handleBuyItemClick = (item, type) => {
    setSelectedBuyItem(item);
    handleChange("btdType", type);
  };
  const handleSellItemClick = (item, type) => {
    setSelectedSellItem(item);
    handleChange("strType", type);
  };

  // reset the buy/sell/floor vlaues when user selects limit price or increaseBy in policy type and adds a value and then switch back to No Configuration or Currect Price)
  useEffect(() => {
    if (formValues.buy !== "current_price") {
      handleChange("current_price_buy", null);
    }

    if (formValues.buy === "current_price") {
      handleChange("current_price_buy", true);
    }

    if (formValues.buy === null || formValues.buy === "current_price") {
      handleChange("buyValue", null);
    }

    if (formValues.sell !== "current_price") {
      handleChange("current_price_sell", null);
    }

    if (formValues.sell === "current_price") {
      handleChange("current_price_sell", true);
    }

    if (formValues.sell === null || formValues.sell === "current_price") {
      handleChange("sellValue", null);
    }
    if (formValues.floor === null) {
      handleChange("floorValue", null);
    }

    if (formValues.buy === null) {
      handleChange("completeOnSell", null);
      handleChange("current_price_buy", null);
    }
  }, [formValues.buy, formValues.sell, formValues.floor]);

  useEffect(() => {
    if (formValues.sell === null) {
      handleChange("completeOnSell", null);
      setIsSellMaxLimitEnabled(false);
      handleChange("highSellValue", null);
      handleChange("minimumProfit", null);
      setIsDCAEnabledForSell(false);
      setIsSellMinimumProfitEnabled(false);
    }

    if (formValues.sell === "increase_by") {
      setIsSellMaxLimitEnabled(false);
      handleChange("highSellValue", null);
      setIsDCAEnabledForSell(false);
    }

    if (formValues.sell === "limit_price") {
      handleChange("minimumProfit", null);
      setIsSellMinimumProfitEnabled(false);
    }

    if (formValues.buy === null) {
      setIsDCAEnabledForBuy(false);
    }

    if (formValues.floor === null) {
      handleChange("liquidateOnFloor", null);
      handleChange("cancelOnFloor", null);
      handleChange("minimumLoss", null);
      setIsFloorMinimumLossEnabled(false);
    }

    if (formValues.floor === "limit_price") {
      handleChange("minimumLoss", null);
      setIsFloorMinimumLossEnabled(false);
    }
  }, [formValues.sell, formValues.buy, formValues.floor]);

  // reset high sell value when SellMaxLimitEnabled is not enabled
  useEffect(() => {
    if (!isSellMaxLimitEnabled) {
      setFormValues({
        ...formValues,
        highSellValue: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSellMaxLimitEnabled]);

  useEffect(() => {
    if (!isSellMinimumProfitEnabled) {
      setFormValues({
        ...formValues,
        minimumProfit: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSellMinimumProfitEnabled]);

  useEffect(() => {
    if (!isFloorMinimumLossEnabled) {
      setFormValues({
        ...formValues,
        minimumLoss: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFloorMinimumLossEnabled]);

  // reset DCA values for buy when DCA is false
  useEffect(() => {
    if (!isDCAEnabledForBuy) {
      setFormValues({
        ...formValues,
        buyDCAValue: null,
        buyDCAUnit: null,
        btd: null,
        buyTwap: null,
        buyTwapTime: null,
        buyTwapTimeUnit: null,
        btdValue: null,
        btdType: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDCAEnabledForBuy]);

  // reset DCA values for sell when DCA is false
  useEffect(() => {
    if (!isDCAEnabledForSell) {
      setFormValues({
        ...formValues,
        sellDCAValue: null,
        sellDCAUnit: null,
        str: null,
        sellTwap: null,
        sellTwapTime: null,
        sellTwapTimeUnit: null,
        strValue: null,
        strType: null,
      });

      setIsSellMaxLimitEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDCAEnabledForSell]);

  // reset buy DCA values when switch btw TWAP and BTD
  useEffect(() => {
    if (formValues.btd) {
      if (formValues.btdType === null) {
        setSelectedBuyItem("+%");
        setFormValues({
          ...formValues,
          buyTwap: null,
          btdType: "increase_by",
          buyTwapTime: null,
          buyTwapTimeUnit: null,
        });
      } else
        setFormValues({
          ...formValues,
          buyTwap: null,
          buyTwapTime: null,
          buyTwapTimeUnit: null,
        });
    }

    if (formValues.buyTwap) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        btd: null,
        btdValue: null,
        btdType: null,
      }));

      if (formValues.buyTwapTimeUnit === null) {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          buyTwapTimeUnit: "days",
        }));
      }
    }
  }, [formValues.btd, formValues.buyTwap]);

  // reset sell DCA values when switch btw TWAP and STR
  useEffect(() => {
    if (formValues.str) {
      if (formValues.strType === null) {
        setSelectedSellItem("+%");
        setFormValues({
          ...formValues,
          sellTwap: null,
          strType: "increase_by",
          sellTwapTime: null,
          sellTwapTimeUnit: null,
        });
      } else {
        setFormValues({
          ...formValues,
          sellTwap: null,
          sellTwapTime: null,
          sellTwapTimeUnit: null,
        });
      }
    }
    if (formValues.sellTwap) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        str: null,
        strValue: null,
        strType: null,
      }));

      if (formValues.sellTwapTimeUnit === null) {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          sellTwapTimeUnit: "days",
        }));
      }
    }
  }, [formValues.str, formValues.sellTwap]);

  useEffect(() => {
    setTimeout(function () {
      setShowPopup(false);
    }, 18000);
  }, []);

  useEffect(() => {
    setBudgetCurrency(formValues.stableToken);
  }, [formValues.stableToken]);

  useEffect(() => {
    const investToken = tokenOptions.find(
      (token) => token.symbol === formValues.investToken
    );
    const stableToken = tokenOptions.find(
      (token) => token.symbol === formValues.stableToken
    );

    if (investToken) {
      setSelectedInvestTokenDetails(investToken);
    }

    if (stableToken) {
      setSelectedStableTokenDetails(stableToken);
    }
  }, [formValues.investToken, formValues.stableToken, tokenOptions]);

  return (
    <div>
      {isCntToNotSupportedChain ? (
        <>
          <div className="overlap-group">
            <div className="wallet-listing d-flex flex-wrap mx-auto">
              <div className="d-flex h-100 align-items-center justify-content-center flex-column w-100 empty-div-portfolio">
                <img src={EmptyIcon} alt="Icon" />
                <h3 className="text-white text-center">
                  Wrong Network Connected
                </h3>
                <p>
                  App network doesn't match the network selected in the wallet,
                  please
                </p>
                <button
                  className="common-button mt-4"
                  onClick={() => switchNetwork(selectedChainId)}
                >
                  Switch the Network
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className="overlap-group">
            {/* {showPopup ? (
        <>
          <div className="popup-outer">
            <button
              className="bg-transparent cross-btn d-flex align-items-center justify-content-center"
              onClick={closePopup}
            >
              <svg
                width="24px"
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
              >
                <g id="grid_system" />
                <g id="_icons">
                  <path
                    stroke="#fff"
                    fill="#fff"
                    d="M5.3,18.7C5.5,18.9,5.7,19,6,19s0.5-0.1,0.7-0.3l5.3-5.3l5.3,5.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3   c0.4-0.4,0.4-1,0-1.4L13.4,12l5.3-5.3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L12,10.6L6.7,5.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4   l5.3,5.3l-5.3,5.3C4.9,17.7,4.9,18.3,5.3,18.7z"
                  />
                </g>
              </svg>
            </button>
            <div className="popup-content">
              <h2>
                Fyda Trading Competition is Live. <span></span>
              </h2>
              <p>
                Join Fyda's Trading Competition and Unlock Exciting Rewards
                and Prizes!
              </p>
              <div className="d-flex align-items-center">
                <a
                  href="https://fyda-fi.medium.com/explore-fydas-simulation-tutorial-bd2ee86aecd6"
                  target="_blank"
                  className="custom-button me-3 mt-4" rel="noreferrer"
                >
                  Tutorial
                </a>
                <a
                  href="https://zealy.io/c/fydafinance/questboard/b015e9bd-a0a2-43db-b15a-422defcdeb46"
                  target="_blank"
                  className="custom-button ghost mt-4" rel="noreferrer"
                >
                  Participate
                </a>
              </div>
            </div>
            <div class="meter">
              <span className="progress-bars">
                <span class="progresss"></span>
              </span>
            </div>
          </div>
        </>
      ) : (
        <></>
      )} */}

            {!showConfirmation && (
              <div
                className={
                  formValues.investToken && formValues.stableToken
                    ? "content-box active"
                    : "content-box"
                }
              >
                <Row className="box-header justify-content-between">
                  <div className="box-header">
                    <h5>Create Strategy</h5>

                    {/* <div class="dropdown custom-dropdown">
                      <button
                        class="bg-transparent border-0 p-0 dropdown-toggle"
                        type="button"
                        id="dropdownMenuClickableInside"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                      >
                        <img
                          onClick={handleOpenSlippage}
                          className="box-header-icon"
                          src={SettingIcon}
                          alt="Analytics Logo"
                        />
                      </button>
                      <div
                        className="custom-dropdown-setting bg-transparent dropdown-menu"
                        aria-labelledby="dropdownMenuClickableInside"
                      >
                        <SlippageFees
                          onClose={handleBackFromSlippage}
                          // onBack={onBack}
                          setCustomSlippage={setCustomSlippage}
                          customSlippage={customSlippage}
                          handleChange={handleChange}
                        />
                      </div>
                    </div> */}
                  </div>
                </Row>

                <div className="box-body-content">
                  <div className="row">
                    <div
                      className={
                        formValues.investToken && formValues.stableToken
                          ? "col-md-12 col-lg-6 pe-lg-4 center-line position-relative"
                          : "col-md-12"
                      }
                    >
                      <div className="type-value p-0 mb-3">
                        <div className="select-container buy-sell-token">
                          <label>Buy and Sell</label>
                          <SelectWithDialog
                            options={tokenOptions}
                            onSelect={handleBuySellTokenSelect}
                            isTokensLoading={isTokensLoading}
                            selectedToken={formValues.investToken}
                            selectButtonLabel={
                              formValues.investToken
                                ? formValues.investToken
                                : "Select Token"
                            }
                            formValues={formValues}
                          />
                        </div>
                        <div className="select-container">
                          <label>With</label>
                          <SelectWithDialog
                            options={tokenOptions}
                            onSelect={handleWithTokenSelect}
                            isTokensLoading={isTokensLoading}
                            selectedToken={formValues.stableToken}
                            selectButtonLabel={
                              formValues.stableToken
                                ? formValues.stableToken
                                : "Select Token"
                            }
                            formValues={formValues}
                          />
                        </div>
                      </div>

                      {selectedInvestTokenDetails &&
                        selectedStableTokenDetails &&
                        formValues.investToken &&
                        formValues.stableToken && (
                          <>
                            <div className="Configure-info mb-3">
                              <p>
                                <span className="Configure-icon">
                                  <InfoOutlinedIcon />
                                </span>
                                1 {formValues.investToken} = {investStable}{" "}
                                {formValues.stableToken} ($
                                {investUSD})
                              </p>
                            </div>

                            <Row className="mb-4">
                              <AmountInputWithToggle // set budget
                                budgetCurrency={budgetCurrency}
                                setInvestAmount={setInvestAmount}
                                setStableAmount={setStableAmount}
                                formValues={formValues}
                                setBudgetCurrency={setBudgetCurrency}
                                selectedOptions={[
                                  {
                                    name: selectedWithToken?.name,
                                    symbol: selectedWithToken?.symbol,
                                  },
                                  {
                                    name: selectedBuySellToken?.name,
                                    symbol: selectedBuySellToken?.symbol,
                                  },
                                ]}
                                selectedInvestTokenDetails={
                                  selectedInvestTokenDetails
                                }
                                selectedStableTokenDetails={
                                  selectedStableTokenDetails
                                }
                              />
                            </Row>
                            <div className="tabs-section custom-tabs-section">
                              <Tab // floor buy sell Tab
                                options={tabOptions}
                                selectedOption={selectedTab}
                                onSelect={handleTabSelect}
                              />
                            </div>
                          </>
                        )}
                    </div>
                    {formValues.investToken && formValues.stableToken && (
                      <div className="col-lg-6 ps-lg-4">
                        {/* {(formValues.investAmount || formValues.stableAmount) && ( */}
                        <>
                          <div className="floor-card mb-4">
                            <div className="type-value-1 p-0 mb-4">
                              <div className="mb-3">
                                <label>{selectedTab} Policy Type</label>
                                <DropdownSelect
                                  className="type-dropdown"
                                  options={
                                    selectedTab === "Buy"
                                      ? buyOptions
                                      : selectedTab === "Sell"
                                      ? sellOptions
                                      : floorOptions
                                  }
                                  value={formValues[selectedTab.toLowerCase()]}
                                  onSelect={handleSelect}
                                  handleChange={handleChange}
                                  name={selectedTab}
                                  placeholder="Not Configured"
                                />
                              </div>
                              <div>
                                {!(
                                  formValues[
                                    selectedTab.toLocaleLowerCase()
                                  ] === null ||
                                  formValues[
                                    selectedTab.toLocaleLowerCase()
                                  ] === "no_configuration" ||
                                  formValues[
                                    selectedTab.toLocaleLowerCase()
                                  ] === "current_price"
                                ) && ( // hide input box when selected dropdown is No Configuration and current price
                                  <>
                                    <label>
                                      Enter {selectedTab}{" "}
                                      {formValues[
                                        selectedTab.toLocaleLowerCase()
                                      ] === "increase_by" ||
                                      formValues[
                                        selectedTab.toLocaleLowerCase()
                                      ] === "decrease_by"
                                        ? formValues[
                                            selectedTab.toLocaleLowerCase() +
                                              "Type"
                                          ] === "decrease_by"
                                          ? "Loss Percentage"
                                          : "Profit Percentage"
                                        : "Price"}{" "}
                                    </label>
                                    <AmountInputWithUnit
                                      name={
                                        selectedTab.toLocaleLowerCase() +
                                        "Value"
                                      }
                                      stableToken={formValues.stableToken}
                                      formValues={formValues}
                                      selectedTab={selectedTab}
                                      onAmountChange={handleChange}
                                    />
                                  </>
                                )}
                                {/* <Input placeholder="Type" type="text" /> */}
                              </div>
                            </div>
                            {selectedTab === "Floor" &&
                              formValues.floor !== null && (
                                <>
                                  {formValues.buy !== null && (
                                    <div className="tabs-section custom-check-tabs d-flex align-items-center justify-content-between">
                                      <p className="d-flex align-items-center custom-label mb-0 me-2">
                                        <img
                                          src={CancelStrategyIcon}
                                          alt="Icon"
                                          className="me-2"
                                        />
                                        Stop Trading
                                        <span className="tool-tip-container ms-2 d-flex">
                                          <Tooltip text="Tooltip content goes here">
                                            Cancel and stop trading when the
                                            floor is breached
                                          </Tooltip>
                                        </span>
                                      </p>
                                      <CustomCheckbox
                                        handleChange={handleChange}
                                        name={"cancelOnFloor"}
                                        value={formValues.cancelOnFloor}
                                        label=""
                                        formValues={formValues}
                                      />
                                    </div>
                                  )}
                                  <div className="tabs-section custom-check-tabs d-flex align-items-center justify-content-between">
                                    <p className="d-flex align-items-center custom-label mb-0 me-2">
                                      <img
                                        src={LiquidationIcon}
                                        alt="Icon"
                                        className="me-2"
                                      />
                                      Liquidation
                                      <span className="tool-tip-container ms-2 d-flex">
                                        <Tooltip text="Tooltip content goes here">
                                          Sell all tokens when the floor is
                                          breached
                                        </Tooltip>
                                      </span>
                                    </p>
                                    <CustomCheckbox
                                      handleChange={handleChange}
                                      name={"liquidateOnFloor"}
                                      value={formValues.liquidateOnFloor}
                                      label=""
                                      formValues={formValues}
                                    />
                                  </div>
                                  {formValues.floor === "decrease_by" && (
                                    <div>
                                      <div className="tabs-section custom-check-tabs d-flex align-items-center justify-content-between">
                                        <p className="d-flex align-items-center custom-label mb-0 me-2">
                                          <img
                                            src={MaxSellIcon}
                                            alt="Icon"
                                            className="me-2"
                                          />
                                          Minimum Loss Threshold
                                          <span className="tool-tip-container ms-2 d-flex">
                                            <Tooltip text="Tooltip content goes here">
                                              Only sell if the loss incurred
                                              falls above the specified minimum
                                              threshold.
                                            </Tooltip>
                                          </span>
                                        </p>
                                        <CustomCheckbox
                                          handleChange={handleChange}
                                          setIsDCAEnabledForSell={
                                            setIsFloorMinimumLossEnabled
                                          }
                                          value={isFloorMinimumLossEnabled}
                                          label=""
                                        />
                                      </div>
                                      {isFloorMinimumLossEnabled && (
                                        <div className="type-value-1 p-0 mb-4">
                                          <label>
                                            Enter Floor Minimum Loss
                                          </label>
                                          <AmountInputWithUnit
                                            name={"minimumLoss"}
                                            stableToken={formValues.stableToken}
                                            formValues={formValues}
                                            onAmountChange={handleChange}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </>
                              )}
                            {selectedTab === "Buy" && // if floor is decreaseBy, DCA for buy should not be shown
                              formValues.buy !== null && (
                                <>
                                  <div className="tabs-section custom-check-tabs d-flex align-items-center justify-content-between">
                                    <p className="d-flex align-items-center custom-label mb-0 me-2">
                                      <img
                                        src={DCAIcon}
                                        alt="Icon"
                                        className="me-2"
                                      />
                                      Dollar Cost Averaging
                                      <span className="tool-tip-container ms-2 d-flex">
                                        <Tooltip text="Tooltip content goes here">
                                          Spread your buy orders over time or
                                          spikes or dips.
                                        </Tooltip>
                                      </span>
                                    </p>
                                    <CustomCheckbox
                                      handleChange={handleChange}
                                      setIsDCAEnabledForBuy={
                                        setIsDCAEnabledForBuy
                                      }
                                      value={isDCAEnabledForBuy}
                                      label=""
                                    />
                                  </div>
                                  {isDCAEnabledForBuy && (
                                    <>
                                      <Row className="dca-check">
                                        <div className="p-0">
                                          <div className="mb-3">
                                            <Inputwithtoggle
                                              name="buyDCAValue"
                                              name2="buyDCAUnit"
                                              value={formValues.buyDCAValue}
                                              handleChange={handleChange}
                                              currencyOptions={
                                                usdCurrencyOptions
                                              }
                                              defaultCurrency={
                                                // wip: logic can be simplified
                                                formValues.buyDCAUnit
                                                  ? formValues.buyDCAUnit ===
                                                    "fixed"
                                                    ? formValues.stableToken
                                                    : "%"
                                                  : formValues.stableToken
                                              }
                                              amountLabel="Spread Amount"
                                            />
                                          </div>

                                          <div className="mb-3">
                                            <label>DCA Type</label>
                                            <DropdownSelect
                                              className="type-dropdown"
                                              options={buyDcaOptions}
                                              value={
                                                formValues.btd
                                                  ? "buy_the_dip"
                                                  : formValues.buyTwap
                                                  ? "twap"
                                                  : "no_configuration"
                                              }
                                              // name="dsatype"
                                              isDcaDropdown={true}
                                              onSelect={handleSelect}
                                              handleChange={handleChange}
                                              selectedTab={selectedTab}
                                              placeholder="Not Configured"
                                            />
                                          </div>

                                          {formValues.buyTwap && (
                                            <Inputwithtoggle
                                              className="m-t-20"
                                              name="buyTwapTime"
                                              name2="buyTwapTimeUnit"
                                              handleChange={handleChange}
                                              currencyOptions={frequencyOptions}
                                              value={formValues.buyTwapTime}
                                              defaultCurrency={
                                                formValues.buyTwapTimeUnit
                                                  ? formValues.buyTwapTimeUnit ===
                                                    "days"
                                                    ? "Days"
                                                    : "Hours"
                                                  : "Days"
                                              }
                                              amountLabel="Frequency"
                                            />
                                          )}
                                          {formValues.btd && (
                                            <>
                                              <ToggleOptions
                                                selectedItem={selectedBuyItem}
                                                formValues={formValues}
                                                handleItemClick={
                                                  handleBuyItemClick
                                                }
                                              />
                                              <div className="type-value-1 p-0 mb-4">
                                                <AmountInputWithUnit
                                                  name={"btdValue"}
                                                  stableToken={selectedBuyItem}
                                                  formValues={formValues}
                                                  // selectedTab={selectedTab}
                                                  onAmountChange={handleChange}
                                                />
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </Row>
                                    </>
                                  )}
                                </>
                              )}
                            {selectedTab === "Sell" &&
                              formValues.sell !== null && (
                                <>
                                  {formValues.buy && (
                                    <div className="tabs-section custom-check-tabs d-flex align-items-center justify-content-between">
                                      <p className="d-flex align-items-center custom-label mb-0 me-2">
                                        <img
                                          src={CancelStrategyIcon}
                                          alt="Icon"
                                          className="me-2"
                                        />
                                        Non Recurring
                                        <span className="tool-tip-container ms-2 d-flex">
                                          <Tooltip text="Tooltip content goes here">
                                            Cease the strategy once all tokens
                                            have been sold.
                                          </Tooltip>
                                        </span>
                                      </p>
                                      <CustomCheckbox
                                        handleChange={handleChange}
                                        value={formValues.completeOnSell}
                                        label=""
                                        name={"completeOnSell"}
                                        formValues={formValues}
                                      />
                                    </div>
                                  )}
                                  {formValues.sell !== "increase_by" && ( // when sell is increase_by then show only Non Recurring checkbox
                                    <>
                                      <div className="tabs-section custom-check-tabs d-flex align-items-center justify-content-between">
                                        <p className="d-flex align-items-center custom-label mb-0 me-2">
                                          <img
                                            src={DCAIcon}
                                            alt="Icon"
                                            className="me-2"
                                          />
                                          Spread Sell Orders
                                          <span className="tool-tip-container ms-2 d-flex">
                                            <Tooltip text="Tooltip content goes here">
                                              Spread your sell orders over time
                                              or spikes or dips.
                                            </Tooltip>
                                          </span>
                                        </p>
                                        <CustomCheckbox
                                          handleChange={handleChange}
                                          setIsDCAEnabledForSell={
                                            setIsDCAEnabledForSell
                                          }
                                          value={isDCAEnabledForSell}
                                          label=""
                                        />
                                      </div>
                                    </>
                                  )}

                                  {formValues.sell === "increase_by" && (
                                    <div>
                                      <div className="tabs-section custom-check-tabs d-flex align-items-center justify-content-between">
                                        <p className="d-flex align-items-center custom-label mb-0 me-2">
                                          <img
                                            src={MaxSellIcon}
                                            alt="Icon"
                                            className="me-2"
                                          />
                                          Minimum Profit Threshold
                                          <span className="tool-tip-container ms-2 d-flex">
                                            <Tooltip text="Tooltip content goes here">
                                              Sell all the tokens when the price
                                              reaches the max limit price.
                                            </Tooltip>
                                          </span>
                                        </p>
                                        <CustomCheckbox
                                          handleChange={handleChange}
                                          setIsDCAEnabledForSell={
                                            setIsSellMinimumProfitEnabled
                                          }
                                          value={isSellMinimumProfitEnabled}
                                          label=""
                                        />
                                      </div>
                                      {isSellMinimumProfitEnabled && (
                                        <div className="type-value-1 p-0 mb-4">
                                          <label>Enter Sell Min Profit</label>
                                          <AmountInputWithUnit
                                            stableToken={formValues.stableToken}
                                            formValues={formValues}
                                            name={"minimumProfit"}
                                            onAmountChange={handleChange}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  {isDCAEnabledForSell && (
                                    <>
                                      <Row className="dca-check">
                                        <div className="p-0">
                                          <div className="mb-3">
                                            <Inputwithtoggle
                                              name="sellDCAValue"
                                              name2="sellDCAUnit"
                                              handleChange={handleChange}
                                              currencyOptions={
                                                investCurrencyOptions
                                              }
                                              defaultCurrency={
                                                formValues.sellDCAUnit
                                                  ? formValues.sellDCAUnit ===
                                                    "fixed"
                                                    ? formValues.investToken
                                                    : "%"
                                                  : formValues.investToken
                                              }
                                              value={formValues.sellDCAValue}
                                              amountLabel="Spread Amount"
                                            />
                                          </div>

                                          <div className="mb-3">
                                            <label>Spread Type</label>
                                            <DropdownSelect
                                              className="type-dropdown"
                                              options={sellDcaOptions}
                                              value={
                                                formValues.str
                                                  ? "sell_the_rally"
                                                  : formValues.sellTwap
                                                  ? "twap"
                                                  : "no_configuration"
                                              }
                                              // name="dsatypesell"
                                              isDcaDropdown={true}
                                              onSelect={handleSelect}
                                              handleChange={handleChange}
                                              selectedTab={selectedTab}
                                              placeholder="Not Configured"
                                            />
                                          </div>

                                          {formValues.sellTwap && (
                                            <div className="mb-3">
                                              <Inputwithtoggle
                                                className="m-t-20"
                                                name="sellTwapTime"
                                                name2="sellTwapTimeUnit"
                                                handleChange={handleChange}
                                                currencyOptions={
                                                  frequencyOptions
                                                }
                                                value={formValues.sellTwapTime}
                                                defaultCurrency={
                                                  formValues.sellTwapTimeUnit
                                                    ? formValues.sellTwapTimeUnit ===
                                                      "days"
                                                      ? "Days"
                                                      : "Hours"
                                                    : "Days"
                                                }
                                                amountLabel="Frequency"
                                              />
                                            </div>
                                          )}
                                          {formValues.str && (
                                            <>
                                              <ToggleOptions
                                                selectedItem={selectedSellItem}
                                                formValues={formValues}
                                                handleItemClick={
                                                  handleSellItemClick
                                                }
                                              />
                                              <div className="type-value-1 p-0 mb-4">
                                                <AmountInputWithUnit
                                                  name={"strValue"}
                                                  stableToken={selectedSellItem}
                                                  formValues={formValues}
                                                  // selectedTab={selectedTab}
                                                  onAmountChange={handleChange}
                                                />
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </Row>
                                    </>
                                  )}

                                  {isDCAEnabledForSell && (
                                    <div className="tabs-section custom-check-tabs d-flex align-items-center justify-content-between">
                                      <p className="d-flex align-items-center custom-label mb-0 me-2">
                                        <img
                                          src={MaxSellIcon}
                                          alt="Icon"
                                          className="me-2"
                                        />
                                        Maximum Limit Price
                                        <span className="tool-tip-container ms-2 d-flex">
                                          <Tooltip text="Tooltip content goes here">
                                            Sell off all tokens once the price
                                            reaches this threshold.
                                          </Tooltip>
                                        </span>
                                      </p>
                                      <CustomCheckbox
                                        handleChange={handleChange}
                                        setIsDCAEnabledForSell={
                                          setIsSellMaxLimitEnabled
                                        }
                                        value={isSellMaxLimitEnabled}
                                        label=""
                                      />
                                    </div>
                                  )}

                                  {isSellMaxLimitEnabled && (
                                    <div className="type-value-1 p-0 mb-4">
                                      <label>Enter Max Sell Limit Price</label>
                                      <AmountInputWithUnit
                                        name={"highSellValue"}
                                        stableToken={formValues.stableToken}
                                        formValues={formValues}
                                        onAmountChange={handleChange}
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                          </div>
                          <div className="stratergy-prev-btn">
                            {/* <Button label="Preview" onClick={handleButtonClick} className="my-custom-class" /> */}
                            <Button
                              label="Preview"
                              onClick={handleShowConfirmation} // Show the Confirmation component
                              className="my-custom-class m-b-20"
                            >
                              Preview
                            </Button>
                            <ConfirmDialog
                              open={dialogOpen}
                              onClose={handleCloseDialog}
                            />
                          </div>
                        </>
                        {/* // )} */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {showConfirmation ? (
              <Confirmation
                handleChange={handleChange}
                investUsdPerToken={investUSD}
                formValues={formValues}
                onClose={handleBackFromConfirmation}
                setFormValues={setFormValues}
                setShowConfirmation={setShowConfirmation}
                isDCAEnabledForBuy={isDCAEnabledForBuy}
                isDCAEnabledForSell={isDCAEnabledForSell}
                selectedInvestTokenDetails={selectedInvestTokenDetails}
                selectedStableTokenDetails={selectedStableTokenDetails}
                connectedChainDetails={connectedChainDetails}
                customSlippage={customSlippage}
                setOpenApiSnackbar={setOpenApiSnackbar}
                setApiSnackbarMsg={setApiSnackbarMsg}
                setApiError={setApiError}
              />
            ) : null}
          </div>
          <CustomSnackbar
            open={openCustomSnackbar}
            onClose={handleClose}
            message={customSnackbarMsg}
            severity={"warning"}
          />
          <Snackbar
            open={openApiSnackbar}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={apiError ? "error" : "success"}
            >
              {apiSnackbarMsg}
            </Alert>
          </Snackbar>
        </div>
      )}
    </div>
  );
};

export default Trade;
