import { useEffect, useState } from "react";
import SelectWithDialog from "../../components/SelectWithDialog/SelectWithDialog";
import {
  getTokensEndpoint,
  getCheatSheetByTokenEndpoint,
} from "../../api/endpoints";
import CheatSheetSlider from "../../components/CheatSheetComponents/CheatSheetSlider";
import ProgressBar from "../../components/CheatSheetComponents/ProgressBar";
import CheatCard from "../../components/CheatSheetComponents/CheatCard";
import CheatColumnCard from "../../components/CheatSheetComponents/CheatColumnCard";
import EmptyIcon from "../../Assets/empty-portfolio.svg";
import { CircularProgress } from "@mui/material";
import CustomSnackbar from "../../components/CustomSnackbar";

const CheatSheet = () => {
  const [tokenOptions, setTokenOptions] = useState([]);
  const [selectedToken, setSelectedToken] = useState(); // token which is selected from dropdown
  const [cheatsheetDetails, setCheatsheetDetails] = useState();
  const [loadingCheatSheet, setIsLoadingCheatSheet] = useState(false);
  const [apirError, setApiError] = useState(false);

  const [isTokensLoading, setIsTokensLoading] = useState(true); // Added isLoading state
  const handleTokenSelect = (selectedToken) => {
    console.log("Selected token:", selectedToken);
    setSelectedToken(selectedToken);
  };

  useEffect(() => {
    const fetchTokenOptions = async () => {
      try {
        setIsTokensLoading(true);
        const response = await fetch(getTokensEndpoint());
        if (response.ok) {
          const data = await response.json();
          setTokenOptions(data);
        } else {
          console.error("Failed to fetch token options");
        }
      } catch (error) {
        console.error("Error fetching token options:", error);
        setIsTokensLoading(false);
      } finally {
        setIsTokensLoading(false);
      }
    };

    fetchTokenOptions();
  }, []);

  useEffect(() => {
    if (!selectedToken) return;
    const fetchCheatSheetDetails = async () => {
      setIsLoadingCheatSheet(true);
      try {
        const response = await fetch(
          getCheatSheetByTokenEndpoint(selectedToken?.symbol)
        );
        if (response.ok) {
          const data = await response.json();
          setCheatsheetDetails(data);
          setIsLoadingCheatSheet(false);
          setApiError(false);
        } else {
          console.error("Failed to fetch CheatSheet");
          setApiError(true);
        }
      } catch (error) {
        console.error("Error fetching CheatSheet:", error);
        setApiError(true);
      }
    };

    fetchCheatSheetDetails();
  }, [selectedToken]);

  const handleClose = (event, reason) => {
    // close snackbar
    if (reason === "clickaway") {
      return;
    }
    setApiError(false);
  };

  return (
    <>
      <div className="overlap-group">
        <div className=" wallet-container-outer">
          <div className="wallet-header text-center d-flex justify-content-between align-items-center mb-4">
            <h1 className="text-white">Cheat Sheet</h1>
            <SelectWithDialog
              options={tokenOptions}
              isTokensLoading={isTokensLoading}
              onSelect={handleTokenSelect}
              selectButtonLabel={
                selectedToken ? selectedToken.symbol : "Select Token"
              }
              selectedToken={selectedToken?.symbol}
            />
          </div>
          {selectedToken ? (
            apirError ? (
              <CustomSnackbar
                open={apirError}
                onClose={handleClose}
                message={
                  "There is no data available for the selected token. Please choose another token."
                }
                severity={"error"}
              />
            ) : loadingCheatSheet ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : (
              <div className="outer-container pt-2 mt-md-0 mt-4">
                <div className="row">
                  <div className="col-md-6">
                    <CheatCard
                      selectedToken={selectedToken}
                      cheatsheetDetails={cheatsheetDetails}
                    />
                  </div>
                  <div className="col-md-6">
                    <ProgressBar cheatsheetDetails={cheatsheetDetails} />
                  </div>
                </div>
                <div className="column-card-div mt-4 pt-md-2 pt-3">
                  <div className="row">
                    <CheatColumnCard cheatsheetDetails={cheatsheetDetails} />
                  </div>
                </div>
                <CheatSheetSlider
                  type="Pivots"
                  cheatsheetDetails={cheatsheetDetails}
                />
                <CheatSheetSlider
                  type="High & Low Levels"
                  cheatsheetDetails={cheatsheetDetails}
                />
              </div>
            )
          ) : (
            <div className="wallet-listing d-flex flex-wrap mx-auto">
              <div className="d-flex h-100 align-items-center justify-content-center flex-column w-100 empty-div-portfolio">
                <img src={EmptyIcon} alt="Icon" />
                <h3 className="text-white">Select a Token</h3>
                <p>
                  Cheatsheet provides you data for various sources to help you
                  to make better decisions on creating strategies
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CheatSheet;
