const BACKEND_BASEURL = process.env.REACT_APP_BACKEND_URL;

// get tokens
export const getTokensEndpoint = () => `${BACKEND_BASEURL}/tokens?chainID=1`;

// get token icon
export const getTokenIconEndpoint = (symbol) =>
  `${BACKEND_BASEURL}/tokens/logo?token=${symbol}`;

// get token price
export const getTokenPriceEndpoint = (investToken, stableToken) =>
  `${BACKEND_BASEURL}/tokens/investPrice?investToken=${investToken}&stableToken=${stableToken}`;

// post create strategy
export const getCreateStrategyEndpoint = () => `${BACKEND_BASEURL}/strategy`;

// patch cancel strategy
export const getCancelStrategyEndpoint = () => `${BACKEND_BASEURL}/strategy`;

// post create wallet
export const getCreateWalletEndpoint = () => `${BACKEND_BASEURL}/wallet`;

// get wallets
export const getWalletsEndpoint = (address) =>
  `${BACKEND_BASEURL}/wallet?address=${address}`;

// patch add balance
export const getAddBalanceEndpoint = () => `${BACKEND_BASEURL}/wallet/add`;

// get balance transactions
export const getBalanceTransactionEndpoint = (address, token) =>
  `${BACKEND_BASEURL}/trades/token?address=${address}&token=${token}`;

// get strategies
export const getStrategiesByAddressEndpoint = (address, pageNumber) =>
  `${BACKEND_BASEURL}/strategy?address=${address}&page=${pageNumber}`;

// get calculated investment amount
export const getcalInvestAmountEndpoint = (amount, token) =>
  `${BACKEND_BASEURL}/tokens/totalAmount?amount=${amount}&token=${token}`;

// get specific strategy information
export const getStrategyInfoByIdEndpoint = (id) =>
  `${BACKEND_BASEURL}/strategy/strategyId?id=${id}`;

// get filtered strategies by Token
export const getFilteredStrtegiesByTokenEndpoint = (address, token) =>
  `${BACKEND_BASEURL}/strategy/filters?address=${address}&token=${token}`;

// get filtered strategies by Status
export const getFilteredStrtegiesByStatusEndpoint = (address, status) =>
  `${BACKEND_BASEURL}/strategy/filters?address=${address}&status=${status}`;

// get filtered strategies by Token and Status
export const getFilteredStrtegiesByTokenAndStatusEndpoint = (
  address,
  token,
  status
) =>
  `${BACKEND_BASEURL}/strategy/filters?address=${address}&token=${token}&status=${status}`;

//https://fyda-backend.onrender.com/strategy/filters?address=0xA01Bc071a4628FAd908e0Ce0bCa04cCFbeb915DA&status=active

// get cheatsheet
export const getCheatSheetByTokenEndpoint = (token) =>
  `${BACKEND_BASEURL}/cheatSheet?token=${token}`;

// get list of trades of a strategy
export const getTradesByStrategyEndpoint = (strategyId) =>
  `${BACKEND_BASEURL}/trades?strategyId=${strategyId}`;

// get token balance of a user
export const getTokenBalanceEndpoint = (ownerAddress, tokenSymbol) =>
  `${BACKEND_BASEURL}/wallet/tokenBalance?address=${ownerAddress}&token=${tokenSymbol}`;
