// DropdownSelect.js

import React, { useState, useRef, useEffect } from "react";
import "./DropdownSelect.scss";
import DropdownIcon from "../../Assets/dropdown-icon.svg";

const DropdownSelect = ({
  options,
  onSelect,
  placeholder,
  name,
  handleChange,
  value,
  selectedTab,
  isDcaDropdown,
}) => {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleCustomSelect = (option) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [name]: option,
    }));

    setIsOpen(false);
    onSelect(option);

    // this is only for dropdowns under DCA
    if (isDcaDropdown) {
      if (selectedTab === "Buy") {
        if (option.value === "buy_the_dip") {
          handleChange("buyTwap", null);
          handleChange("btd", true);
        }
        if (option.value === "twap") {
          handleChange("btd", null);
          handleChange("buyTwap", true);
        }
        if (option.value === "no_configuration") {
          handleChange("btd", null);
          handleChange("buyTwap", null);
        }
      }
      if (selectedTab === "Sell") {
        if (option.value === "sell_the_rally") {
          handleChange("sellTwap", null);
          handleChange("str", true);
        }
        if (option.value === "twap") {
          handleChange("str", null);
          handleChange("sellTwap", true);
        }
        if (option.value === "no_configuration") {
          handleChange("str", null);
          handleChange("sellTWAP", null);
        }
      }
    }

    if (name && handleChange) {
      handleChange(
        name?.toLowerCase(),
        option.value !== "no_configuration" ? option.value : null
      );
      console.log(
        " Form Vlaues: Type =" + name + "dropdown option: " + option.label
      );
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  // set the default selected option based on the formValues
  // this is to show the previouly selected option when component rerender ex: user comes back from preview popup
  useEffect(() => {
    const defaultOption = options.find((option) => option.value === value);
    if (defaultOption) {
      setSelectedOptions((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [name]: defaultOption,
      }));
    }
  }, [options, name, value]);

  const selectedOption = selectedOptions[name];

  return (
    <div className="dropdown-select" ref={dropdownRef}>
      <div className="selected-option" onClick={() => setIsOpen(!isOpen)}>
        {selectedOption ? (
          <span className="placeholder"><img src={"../../Assets/" + `${selectedOption.symbol}`} className="me-2" />{selectedOption.label}</span>
        ) : (
          <span className="placeholder first-selection">{placeholder}</span>
        )}
        <img src={DropdownIcon} alt="Dropdown Icon" className="dropdown-icon" />
      </div>
      {isOpen && (
        <div className="options-list">
          {options.map((option) => (
            <div
              key={option.value}
              className="option"
              onClick={() => handleCustomSelect(option)}
            >
              <img src={"../../Assets/" + `${option.symbol}`} className="me-2" />{option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownSelect;
