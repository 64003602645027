export const formatDate = (dateString, onlyDate) => {
  const date = new Date(dateString);
  if (onlyDate) {
    // only for cases where we don't want time/sec
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    return date.toLocaleString(undefined, options);
  }
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return date
    .toLocaleString(undefined, options)
    .replace("am", "AM")
    .replace("pm", "PM");
};
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// converts numbers to currencies with suffix ex: 10000 -> 10.00K, 100000000 -> 10.00M
export const formatNumberToCurrency = (amount) => {
  return Math.abs(Number(amount)) >= 1.0e9
    ? (Math.abs(Number(amount)) / 1.0e9).toFixed(2) + " B"
    : Math.abs(Number(amount)) >= 1.0e6
    ? (Math.abs(Number(amount)) / 1.0e6).toFixed(2) + " M"
    : Math.abs(Number(amount)) >= 1.0e3
    ? (Math.abs(Number(amount)) / 1.0e3).toFixed(2) + " K"
    : Math.abs(Number(amount)).toFixed(2);
};

export const addCommasToNumber = (number) => {
  // Split the number into integer and decimal parts to avoid adding commans after decimal (.)
  const parts = String(number).split(".");
  const integerPart = parts[0];
  const decimalPart = parts[1] ? "." + parts[1] : "";

  // Add commas as thousands separators to the integer part
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return formattedInteger + decimalPart;
};

export const isSameDateInUTC = (date1, date2) => {
  return (
    date1.getUTCFullYear() === date2.getUTCFullYear() &&
    date1.getUTCMonth() === date2.getUTCMonth() &&
    date1.getUTCDate() === date2.getUTCDate()
  );
};
