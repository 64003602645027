import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tooltip from "../Tooltips/Tooltip";
import "./CheatSheetSlider.scss";
import { addCommasToNumber } from "../../utils/format-util";
import PivotsImage from "../../Assets/Pivots.svg";
import HighLowImage from "../../Assets/high_low.svg"

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        className="arrow-next"
        width="37"
        height="20"
        viewBox="0 0 37 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35 10.0053H2M26.2 2L35 10L26.2 18"
          stroke="currentColor"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        className="arrow-prev"
        width="37"
        height="20"
        viewBox="0 0 37 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 10.0053H35M10.8 2L2 10L10.8 18"
          stroke="currentColor"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}
const CheatSheetSlider = ({ type, cheatsheetDetails }) => {
  if (!cheatsheetDetails) return;
  const SupportAndResistanceCard = [
    {
      id: 1,
      label: "Support #03",
      value: `$ ${addCommasToNumber(cheatsheetDetails.s3)}`,
    },
    {
      id: 2,
      label: "Support #02",
      value: `$ ${addCommasToNumber(cheatsheetDetails.s2)}`,
    },
    {
      id: 3,

      label: "Support #01",
      value: `$ ${addCommasToNumber(cheatsheetDetails.s1)}`,
    },
    {
      id: 4,

      label: "Pivot",
      value: `$ ${addCommasToNumber(cheatsheetDetails.p)}`,
    },
    {
      id: 5,
      label: "Resistance #01",
      value: `$ ${addCommasToNumber(cheatsheetDetails.r1)}`,
    },
    {
      id: 6,
      label: "Resistance #02",
      value: `$ ${addCommasToNumber(cheatsheetDetails.r2)}`,
    },
    {
      id: 7,
      label: "Resistance #03",
      value: `$ ${addCommasToNumber(cheatsheetDetails.r3)}`,
    },
  ];
  const HighsAndLowsCard = [
    {
      id: 1,
      label: "1 Month High",
      value: `$ ${addCommasToNumber(cheatsheetDetails.highValue30)}`,
    },
    {
      id: 2,
      label: "1 Month Low",
      value: `$ ${addCommasToNumber(cheatsheetDetails.minValue30)}`,
    },
    {
      id: 3,
      label: "3 Months High",
      value: `$ ${addCommasToNumber(cheatsheetDetails.highValue90)}`,
    },
    {
      id: 4,
      label: "3 Months Low",
      value: `$ ${addCommasToNumber(cheatsheetDetails.minValue90)}`,
    },
    {
      id: 5,
      label: "6 Months High",
      value: `$ ${addCommasToNumber(cheatsheetDetails.highValue180)}`,
    },
    {
      id: 6,
      label: "6 Months Low",
      value: `$ ${addCommasToNumber(cheatsheetDetails.minValue180)}`,
    },
  ];

  var settings = {
    dots: false,
    infinite: false,
    padding: 20,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="support-outer mt-md-4 mt-3 pt-md-2">
        <div className="support-container">
          <div className="support-header pb-3 mb-4 d-flex align-items-center justify-content-between">
            <h2 className="text-uppercase d-flex align-items-center text-white">
              {type === "Pivots" &&
                <img className="me-2" width="26" height="21" src={PivotsImage} alt="" />
              }

              {type !== "Pivots" &&
                <img className="me-2" width="26" height="21" src={HighLowImage} alt="" />
              }

              {type}
            </h2>
          </div>
          <div className="row">
            <div className="col-12">
              <Slider {...settings} className="">
                {(type === "Pivots"
                  ? SupportAndResistanceCard
                  : HighsAndLowsCard
                ).map((items, key) => (
                  <div className="support-card-container px-3" key={key.id}>
                    <div className="support-inner-div">
                      <div className="text-center">
                        <p className="mb-0 text-uppercase">{items.label}</p>
                      </div>
                      <div className="support-body-content text-center mt-2">
                        <h3>{items.value}</h3>
                        {/*<p className="mb-0">Per month Charge</p>*/}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheatSheetSlider;
