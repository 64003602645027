import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Inputwithtoggle.scss"; // Import the corresponding CSS
import ToggleSwitch from "../Selectinput/ToggleSwitch"; // Assume you have a ToggleSwitch component

const AmountInputWithToggle = ({
  name,
  name2,
  value,
  handleChange,
  currencyOptions,
  defaultCurrency,
  amountLabel,
}) => {
  const [amount, setAmount] = useState(value);
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency);

  const handleAmountChange = (e) => {
    const newAmount = e.target.value;
    setAmount(newAmount);
    name ? handleChange(name, newAmount) : handleChange(newAmount);
  };

  useEffect(() => {
    const type =
      defaultCurrency === currencyOptions[0]?.symbol
        ? currencyOptions[0]?.value
        : currencyOptions[1].value;

    // initial value is set to fixed
    name2 && handleChange(name2, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency);
    const type =
      currency === currencyOptions[0]?.symbol
        ? currencyOptions[0]?.value
        : currencyOptions[1].value;
    name2 && handleChange(name2, type);
  };

  return (
    <div className="amount-input">
      <div>
        <label htmlFor="amount">{amountLabel}</label>
      </div>

      <div className="input-container">
        <input
          type="number"
          placeholder="0"
          value={amount}
          onChange={handleAmountChange}
          inputMode="decimal"
          min="0"
          onKeyDown={(e) => {
            // prevent input of minus key
            if (e.key === "-" || e.key === "+") {
              e.preventDefault();
            } else if (name.toLowerCase().includes("twap") && e.key === ".") {
              e.preventDefault();
            }
          }}
        />
        <ToggleSwitch
          options={currencyOptions}
          selectedOption={selectedCurrency}
          onSelect={handleCurrencyChange}
        />
      </div>
    </div>
  );
};

AmountInputWithToggle.propTypes = {
  handleChange: PropTypes.func.isRequired,
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  defaultCurrency: PropTypes.string.isRequired,
  amountLabel: PropTypes.string.isRequired,
};

export default AmountInputWithToggle;
