import React, { useEffect, useState, useMemo } from "react";
import "./ModalSelectInput.scss";
import ToggleSwitch from "./ToggleSwitch";
import { usePreventMouseWheel } from "../../utils/input-util";
import { useAccount, useNetwork } from "wagmi";
import { getTokenBalanceEndpoint } from "../../api/endpoints";

const AmountInputWithToggle = ({
  formValues,
  setStableAmount,
  setInvestAmount,
  setBudgetCurrency,
  budgetCurrency,
  selectedOptions,
  selectedInvestTokenDetails,
  selectedStableTokenDetails,
}) => {
  // const { chain } = useNetwork();
  const { address } = useAccount();
  const initialCurrency = formValues.stableToken; // initial currency is set to should be stable token
  const initialAmount = formValues.investAmount || formValues.stableAmount;
  const [amount, setAmount] = useState(initialAmount);
  // const [selectedTokenAddress, setSelectedTokenAddress] = useState(
  //   selectedStableTokenDetails.address
  // );
  const [selectedTokenBalance, setSelectedTokenBalance] = useState();
  const inputRef = React.createRef();

  const handleAmountChange = (event) => {
    const newAmount = event.target.value; // Extract the value from the event
    setAmount(newAmount);
  };

  const handleCurrencyChange = (currency) => {
    setBudgetCurrency(currency);

    // setSelectedTokenAddress(
    //   currency === formValues.investToken
    //     ? selectedInvestTokenDetails.address
    //     : currency === formValues.stableToken
    //     ? selectedStableTokenDetails.address
    //     : null
    // );
  };

  const handleMaxBalance = () => {
    setAmount(selectedTokenBalance.balanceWithoutFormattiong);
  };

  usePreventMouseWheel(inputRef);
  useEffect(() => {
    if (selectedOptions[0]?.symbol === (budgetCurrency || initialCurrency)) {
      setTimeout(() => {
        setStableAmount(amount); // only one Amount can be set at a time
        setInvestAmount(null);
        return;
      }, 800); // Set a delay of 800ms before setting the value, so that UI will not load other fields instantly
    } else if (selectedOptions[1]?.symbol === budgetCurrency) {
      setTimeout(() => {
        setInvestAmount(amount);
        setStableAmount(null);
        return;
      }, 800);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetCurrency, amount]);

  useEffect(() => {
    const fetchTokenBalance = async () => {
      try {
        const response = await fetch(
          getTokenBalanceEndpoint(address, budgetCurrency)
        );
        if (response.ok) {
          const data = await response.json();
          setSelectedTokenBalance(data);
        } else {
          console.error("Failed to fetch token balance");
        }
      } catch (error) {
        console.error("Error fetching token balance:", error);
      }
    };

    fetchTokenBalance();
  }, [
    address,
    budgetCurrency,
    selectedInvestTokenDetails,
    selectedStableTokenDetails,
  ]);

  return (
    <div className="amount-input custom-amount-input">
      <div>
        <label htmlFor="amount">Enter Budget</label>
      </div>

      <div className="input-container flex-wrap justify-content-between">
        <input
          ref={inputRef} // Attach the ref to the input element
          type="number"
          placeholder="0.00"
          value={amount}
          onChange={handleAmountChange}
          inputMode="decimal"
          min="0"
          onKeyDown={(e) => {
            // prevent input of minus key
            if (e.key === "-" || e.key === "+") {
              e.preventDefault();
            }
          }}
        />
        <ToggleSwitch
          options={selectedOptions}
          selectedOption={budgetCurrency ? budgetCurrency : initialCurrency}
          onSelect={handleCurrencyChange}
        />
        {address && (
          <div className="text-end flex-end w-100">
            <p className="balance-text mb-0 d-flex align-items-center justify-content-end mt-2">
              Balance: <span>{selectedTokenBalance?.balance}</span>{" "}
              <button className="border-0" onClick={handleMaxBalance}>
                MAX
              </button>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AmountInputWithToggle;
