import { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./Datepicker.scss";
import CalenderIcon from "../../Assets/calender.svg";
import dayjs from "dayjs"; // Import dayjs library
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const Datepicker = ({ label, title, onChange, formValues }) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const handleButtonClick = () => {
    setOpenDatePicker(true);
  };
  dayjs.extend(utc);
  dayjs.extend(timezone);

  return (
    <div className="">
      <div className="date-picker-title">{title}</div>
      <div className="custom-datepicker">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="custom-datepicker-container d-flex align-items-center">
            <DatePicker
              open={openDatePicker}
              onOpen={() => setOpenDatePicker(true)}
              onClose={() => setOpenDatePicker(false)}
              maxDate={dayjs()} // dayjs() gives today's date
              minDate={title === "To" ? formValues?.historicalStartDate : ""} // TO date should always be more than from date
              onChange={onChange}
              timezone="UTC"
              label="Select Date"
              className="custom-datepicker-input"
              format="DD/MM/YYYY"
              value={
                title === "From"
                  ? dayjs.tz(formValues?.historicalStartDate)
                  : dayjs.tz(formValues?.historicalEndDate)
              }
              PopoverProps={{
                style: {
                  backgroundColor: "#fff",
                  color: "#fff",
                },
              }}
              renderDay={(day, _value, DayProps) => (
                <div
                  {...DayProps}
                  style={{
                    color: "#fff", // Change day text color
                  }}
                >
                  {day.format("D")}
                </div>
              )}
            />
            <button
              className="custom-datepicker-icon-button"
              onClick={handleButtonClick}
            >
              <img src={CalenderIcon} alt="Calendar Icon" />
            </button>
          </div>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default Datepicker;

// <LocalizationProvider dateAdapter={AdapterDayjs}>
// <DemoContainer components={["DatePicker"]}>
//   <DatePicker label="Select Date" className="custom-datepicker" />
// </DemoContainer>
// </LocalizationProvider>
