import { Row } from "react-bootstrap";
import "./StrategyInfo.scss";
import { useState, useRef, useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
// import { ChainContext } from "../../App";
import {
  getStrategyInfoByIdEndpoint,
  getCancelStrategyEndpoint,
  getTokenIconEndpoint,
} from "../../api/endpoints";
import { useAccount } from "wagmi";
import { signMessage } from "../../utils/sign-utils";
// import { ethers } from "ethers";
import { formatDate, capitalizeFirstLetter } from "../../utils/format-util";
// import { getChainLogoAndName } from "../../utils/ercToken-utils";
import CustomSnackbar from "../../components/CustomSnackbar";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

const StrategyInformation = () => {
  const query = new URLSearchParams(useLocation().search);
  // const { selectedChainId, isCntToNotSupportedChain } =
  //   useContext(ChainContext);

  const strategyId = query.get("id") || "";
  // const chainId = query.get("chainId") || "";
  const { address } = useAccount();
  const navigate = useNavigate();

  const handleViewTrades = () => {
    navigate(`/trades?strategyId=${strategyId}`);
  };

  // const { connectedChainDetails } = useContext(ChainContext);

  const [strategyInfo, setStrategyInfo] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState();
  const [isStrategyCancelled, setIsStrategyCancelled] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [isInfo, setIsInfo] = useState(true);
  const [strategyCancelProcessing, setStrategyCancelProcessing] = useState(); // use this for adding loader for cancel button
  const isStrategyOwnerLoggedIn = strategyInfo?.address === address;
  // const infuraApiKey = process.env.REACT_APP_INFURA_API_KEY;

  // wip: replace window.ethereum
  // const provider = useMemo(() => {
  //   if (window.ethereum) {
  //     return new ethers.providers.Web3Provider(window.ethereum);
  //   } else {
  //     console.error(
  //       "window.ethereum is undefined. Cannot create Web3Provider."
  //     );
  //     return new ethers.providers.JsonRpcProvider(
  //       `https://polygon-mainnet.infura.io/v3/${infuraApiKey}`
  //     );
  //   }
  // }, [address, window.ethereum]);

  // const signer = provider.getSigner();

  // wip: confirm whether connectedChainDetails should replace with the chain details with the chain that this strategy is created
  // useMemo(() => {
  //   if (!connectedChainDetails) return;
  //   setStrategyFacet(
  //     new ethers.Contract(
  //       connectedChainDetails.address,
  //       StrategyFacetABI,
  //       signer
  //     )
  //   );
  //   setLensFacet(
  //     new ethers.Contract(connectedChainDetails.address, lensFacetABI, signer)
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [connectedChainDetails]); // singer is not added as dependency as it leads to multiple re-renderings

  const handleEdit = () => {
    setIsEdit(true);
    setIsInfo(false);
  };

  const returnEdit = () => {
    setIsEdit(false);
    setIsInfo(true);
  };
  useEffect(() => {
    const fetchStrategyInfo = async () => {
      try {
        const response = await fetch(getStrategyInfoByIdEndpoint(strategyId));
        if (response.ok) {
          const data = await response.json();
          // convert token address to symbols and add it to data
          // const investTokenSymbol = await getTokenSymbol(data.investToken);
          // const stableTokenSymbol = await getTokenSymbol(data.stableToken);

          // const updatedStrategieData = {
          //   ...data,
          //   investTokenSymbol,
          //   stableTokenSymbol,
          // };
          setStrategyInfo({
            ...data,
          });
        } else {
          console.error("Failed to fetch Strategy Info");
        }
      } catch (error) {
        console.error("Error fetching Strategy Info:", error);
      }
    };

    fetchStrategyInfo();
  }, [isStrategyCancelled, strategyId]);

  const handleCancelStrategy = async () => {
    setStrategyCancelProcessing(true);
    try {
      // const userAddress = await signer?.getAddress();

      // const nonce = (await lensFacet?.getNonce(userAddress)).toString();

      // const hash = await strategyFacet.getMessageHashToCancel(
      //   strategyId,
      //   nonce,
      //   userAddress
      // );

      // const messageHashBinary = ethers.utils.arrayify(hash);
      // const signature = await signer.signMessage(messageHashBinary);

      // const data = {
      //   id: strategyId,
      //   nonce: nonce,
      //   signature: signature,
      //   chainId: chainId,
      //   address: userAddress,
      // };
      const signature = await signMessage(
        address,
        `Sign in to verify that you want to cancel the strategy ID #${strategyId}`
      );

      const data = {
        id: strategyId,
        status: "cancelled",
      };

      if (signature) {
        fetch(getCancelStrategyEndpoint(), {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: signature,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (!response.ok) {
              setIsStrategyCancelled(false);
              setOpenSnackbar(true);
              setStrategyCancelProcessing(false);
              setSnackbarMsg("There is an error while canceling the strategy");
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            setStrategyCancelProcessing(false);
            setOpenSnackbar(true);
            setIsStrategyCancelled(true);
            setSnackbarMsg("Strategy cancelled successfully");
            console.log("Strategy has been cancelled:", data);
          })
          .catch((error) => {
            setStrategyCancelProcessing(false);
            setIsStrategyCancelled(false);
            setOpenSnackbar(true);
            setSnackbarMsg("There is an error while canceling the strategy");
            console.error("Error getting user address or nonce:", error);
          });
      }
    } catch (error) {
      if (error.code === 4001) {
        // User rejected the Metamask sign
        setStrategyCancelProcessing(false);
        setIsStrategyCancelled(false);
        setOpenSnackbar(true);
        setSnackbarMsg("Please sign transaction to cancel the strategy");
      } else {
        setStrategyCancelProcessing(false);
        console.error("Error cancelling strategy:", error);
      }
    }
  };

  const handleClose = (event, reason) => {
    // close snackbar
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const [copySuccess, setCopySuccess] = useState("");
  const [isActive, setActive] = useState(false);
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    setActive(!isActive);
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess("Address Copied!");
  }

  setTimeout(() => {
    setActive(false);
  }, 3000);

  return (
    <>
      <div className="overlap-group overlap-new-group">
        {strategyInfo ? (
          isInfo && (
            <div className="content-box strategy-info">
              <Row className="box-header justify-content-between ">
                <div className="header-select justify-content-between d-lg-flex d-block align-items-center">
                  <h2 className="m-0 d-flex align-items-center justify-lg-content-start justify-content-between">
                    Strategy Information
                    <p className="mb-0 ms-3">No #{strategyInfo.id}</p>
                  </h2>
                  <div className="button-div d-lg-flex d-none align-items-center">
                    {/*!(
                    strategyInfo.status === "completed" ||
                    strategyInfo.status === "cancelled"
                  ) &&
                    isStrategyOwnerLoggedIn && (
                      <button
                        className="card-header-button"
                        onClick={handleEdit}
                      >
                        <svg
                          class="feather feather-edit"
                          fill="none"
                          height="15"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                          width="15"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                            stroke="currentColor"
                          />
                          <path
                            stroke="currentColor"
                            d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                          />
                        </svg>
                        Edit
                      </button>
                    )*/}
                    <button
                      className="card-header-button"
                      onClick={handleViewTrades}
                    >
                      <svg
                        width="12"
                        height="15"
                        viewBox="0 0 12 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 2.26744H2.83333V0.523256C2.83333 0.237209 2.60667 0 2.33333 0C2.06 0 1.83333 0.237209 1.83333 0.523256V2.26744H1.66667C0.606667 2.26744 0 2.90233 0 4.01163V8.19767C0 9.30698 0.606667 9.94186 1.66667 9.94186H1.83333V14.4767C1.83333 14.7628 2.06 15 2.33333 15C2.60667 15 2.83333 14.7628 2.83333 14.4767V9.94186H3C4.06 9.94186 4.66667 9.30698 4.66667 8.19767V4.01163C4.66667 2.90233 4.06 2.26744 3 2.26744Z"
                          fill="currentColor"
                        />
                        <path
                          d="M10.3333 5.05814H10.1667V0.523256C10.1667 0.237209 9.94 0 9.66667 0C9.39333 0 9.16667 0.237209 9.16667 0.523256V5.05814H9C7.94 5.05814 7.33333 5.69302 7.33333 6.80233V10.9884C7.33333 12.0977 7.94 12.7326 9 12.7326H9.16667V14.4767C9.16667 14.7628 9.39333 15 9.66667 15C9.94 15 10.1667 14.7628 10.1667 14.4767V12.7326H10.3333C11.3933 12.7326 12 12.0977 12 10.9884V6.80233C12 5.69302 11.3933 5.05814 10.3333 5.05814Z"
                          fill="currentColor"
                        />
                      </svg>
                      View Trades
                    </button>
                    {/* {strategyInfo?.address === address && ( */}
                    {!(
                      strategyInfo.status === "completed" ||
                      strategyInfo.status === "cancelled"
                    ) &&
                      isStrategyOwnerLoggedIn && (
                        <button
                          className={
                            strategyCancelProcessing ? "bg-white" : "bg-normal"
                          }
                          onClick={handleCancelStrategy}
                          disabled={strategyCancelProcessing}
                        >
                          {strategyCancelProcessing ? (
                            <>
                              <CircularProgress />
                            </>
                          ) : (
                            <>
                              <svg
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 11.486L6.243 6.243L11.486 11.486M11.486 1L6.242 6.243L1 1"
                                  stroke="currentColor"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              Cancel
                            </>
                          )}
                        </button>
                      )}
                    {/* )} */}
                  </div>
                </div>
              </Row>

              <div className="card-body-content mt-lg-4 mt-3">
                <div className="row">
                  <div className="col-lg-6 pe-lg-4 center-line position-relative">
                    <div className="card-header d-flex align-items-center justify-content-between">
                      <div
                        className={
                          "live-updates d-flex align-items-center " +
                          ` ${strategyInfo.status}`
                        }
                      >
                        <span className="live-dot"></span>
                        <p className="ms-4 mb-0">
                          {capitalizeFirstLetter(strategyInfo.status)}
                        </p>
                      </div>

                      <div className="button-div d-flex d-lg-none align-items-center">
                        {/*!(
                    strategyInfo.status === "completed" ||
                    strategyInfo.status === "cancelled"
                  ) &&
                    isStrategyOwnerLoggedIn && (
                      <button
                        className="card-header-button"
                        onClick={handleEdit}
                      >
                        <svg
                          class="feather feather-edit"
                          fill="none"
                          height="15"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                          width="15"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                            stroke="currentColor"
                          />
                          <path
                            stroke="currentColor"
                            d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                          />
                        </svg>
                        Edit
                      </button>
                    )*/}
                        <button
                          className="card-header-button"
                          onClick={handleViewTrades}
                        >
                          <svg
                            width="12"
                            height="15"
                            viewBox="0 0 12 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3 2.26744H2.83333V0.523256C2.83333 0.237209 2.60667 0 2.33333 0C2.06 0 1.83333 0.237209 1.83333 0.523256V2.26744H1.66667C0.606667 2.26744 0 2.90233 0 4.01163V8.19767C0 9.30698 0.606667 9.94186 1.66667 9.94186H1.83333V14.4767C1.83333 14.7628 2.06 15 2.33333 15C2.60667 15 2.83333 14.7628 2.83333 14.4767V9.94186H3C4.06 9.94186 4.66667 9.30698 4.66667 8.19767V4.01163C4.66667 2.90233 4.06 2.26744 3 2.26744Z"
                              fill="currentColor"
                            />
                            <path
                              d="M10.3333 5.05814H10.1667V0.523256C10.1667 0.237209 9.94 0 9.66667 0C9.39333 0 9.16667 0.237209 9.16667 0.523256V5.05814H9C7.94 5.05814 7.33333 5.69302 7.33333 6.80233V10.9884C7.33333 12.0977 7.94 12.7326 9 12.7326H9.16667V14.4767C9.16667 14.7628 9.39333 15 9.66667 15C9.94 15 10.1667 14.7628 10.1667 14.4767V12.7326H10.3333C11.3933 12.7326 12 12.0977 12 10.9884V6.80233C12 5.69302 11.3933 5.05814 10.3333 5.05814Z"
                              fill="currentColor"
                            />
                          </svg>
                          View Trades
                        </button>
                        {/* {strategyInfo?.address === address && ( */}
                        {!(
                          strategyInfo.status === "completed" ||
                          strategyInfo.status === "cancelled" ||
                          strategyInfo.status === "historical"
                        ) &&
                          isStrategyOwnerLoggedIn && (
                            <button
                              className={
                                strategyCancelProcessing
                                  ? "bg-white"
                                  : "bg-normal"
                              }
                              onClick={handleCancelStrategy}
                            >
                              {strategyCancelProcessing ? (
                                <>
                                  <CircularProgress />
                                </>
                              ) : (
                                <>
                                  <svg
                                    width="13"
                                    height="13"
                                    viewBox="0 0 13 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 11.486L6.243 6.243L11.486 11.486M11.486 1L6.242 6.243L1 1"
                                      stroke="currentColor"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  Cancel
                                </>
                              )}
                            </button>
                          )}
                        {/* )} */}
                      </div>
                    </div>
                    <h2 className="my-3">
                      ${strategyInfo.totalValue}
                      <span
                        className={
                          strategyInfo.profitLossPercentage.startsWith("-")
                            ? "down-arrow"
                            : "up-arrow"
                        }
                      >
                        <i className=""></i>
                        {strategyInfo?.profitLossAmountUSD +
                          "(" +
                          strategyInfo?.profitLossPercentage?.replace('$', '') +
                          "%)" +
                          ""}
                      </span>
                    </h2>
                    <ul className="analyse-inner-listing ps-0 mb-0">
                      <li>
                        <div className="d-flex align-items-center justify-content-between">
                          <p>Initial {strategyInfo.stableToken} Balance</p>
                          <h3>
                            {strategyInfo.initialStableAmount}{" "}
                            {strategyInfo.stableToken}
                          </h3>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center justify-content-between">
                          <p>Current {strategyInfo.stableToken} Balance</p>
                          <h3>
                            {strategyInfo.stableAmount}{" "}
                            {strategyInfo.stableToken}
                          </h3>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center justify-content-between">
                          <p>Initial {strategyInfo.investToken} Balance</p>
                          <h3>
                            {strategyInfo.initialInvestAmount}{" "}
                            {strategyInfo.investToken}
                          </h3>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center justify-content-between">
                          <p>Current {strategyInfo.investToken} Balance</p>
                          <h3>
                            {strategyInfo.investAmount}{" "}
                            {strategyInfo.investToken}
                          </h3>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center justify-content-between">
                          <p>Created At</p>
                          <h3>{formatDate(strategyInfo.createdAt)}</h3>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center justify-content-between">
                          <p>Budget</p>
                          <h3>
                            {strategyInfo.budget} {strategyInfo.stableToken}
                          </h3>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center justify-content-between">
                          <p>Account address</p>
                          <h3 className="d-flex align-items-center justify-content-center position-relative">
                            {`${strategyInfo.address.slice(
                              0,
                              7
                            )}...${strategyInfo.address.slice(-6)}`}
                            <div className="tooltip-container">
                              <div
                                className="tooltip-icon"
                                onClick={copyToClipboard}
                              >
                                <span>
                                  {isActive ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 448 512"
                                      className="ms-2 claim-icon"
                                      width="18px"
                                      height="18px"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      className="ms-2 claim-icon"
                                      width="18px"
                                      height="18px"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 448 512"
                                    >
                                      {" "}
                                      <path
                                        fill="currentColor"
                                        d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z"
                                      />
                                    </svg>
                                  )}
                                </span>
                              </div>
                              <div className="tooltip-content">
                                {isActive ? "Copied" : "Copy Address"}
                              </div>
                            </div>
                            <div className="outer-hover-div">
                              <p> {strategyInfo.address}</p>
                              <textarea
                                className="bg-transparent border-0 text-white"
                                ref={textAreaRef}
                                maxLength="200"
                                readOnly
                              >
                                {strategyInfo.address}
                              </textarea>
                            </div>
                          </h3>
                        </div>
                      </li>
                      {/*<li>
                        <div className="d-flex align-items-center justify-content-between">
                          <p>Network</p>
                          <h3>
                            <img
                              alt="token icon"
                              src={
                                getChainLogoAndName(strategyInfo.chainId).logo
                              }
                              className="me-2"
                            />
                            {
                              getChainLogoAndName(strategyInfo.chainId)
                                .chainName
                            }
                          </h3>
                        </div>
                          </li>*/}
                      <li>
                        <div className="d-flex align-items-center justify-content-between">
                          <p>Average Buy Price</p>
                          <h3>
                            {strategyInfo.investPrice
                              ? strategyInfo.investPrice +
                                " " +
                                strategyInfo.stableToken
                              : "-"}
                          </h3>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6 ps-lg-4">
                    <div className="d-flex align-items-center justify-content-between para-text mt-0">
                      <h3>Investment Amount</h3>
                      <span>${strategyInfo.totalInitialValue}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between para-text">
                      <h4>Invest Token</h4>
                      <span>
                        {strategyInfo.investToken}{" "}
                        <img
                          src={getTokenIconEndpoint(strategyInfo.investToken)}
                          onError={(e) => {
                            e.target.src = `https://coinicons-api.vercel.app/api/icon/${strategyInfo.investToken?.toLowerCase()}`;
                          }}
                        />
                      </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between para-text">
                      <h4>Stable Token</h4>
                      <span>
                        {strategyInfo.stableToken}{" "}
                        <img
                          src={getTokenIconEndpoint(strategyInfo.stableToken)}
                          onError={(e) => {
                            e.target.src = `https://coinicons-api.vercel.app/api/icon/${strategyInfo.stableToken?.toLowerCase()}`;
                          }}
                        />{" "}
                      </span>
                    </div>
                    {strategyInfo.historical && (
                      <>
                        <div className="d-flex align-items-center justify-content-between para-text">
                          <h4>Start Date</h4>
                          <span>
                            {formatDate(strategyInfo.startDate, true)}{" "}
                          </span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between para-text">
                          <h4>End Date</h4>
                          <span>{formatDate(strategyInfo.endDate, true)} </span>
                        </div>
                      </>
                    )}
                    <div className="d-flex align-items-center justify-content-between para-text mt-4">
                      <h3>Configuration :</h3>
                      <span></span>
                    </div>
                    <ul className="analyse-inner-listing ps-0 mb-0 mt-2">
                      {strategyInfo.floor && (
                        <>
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Floor</p>
                              <h3>
                                {strategyInfo.floor === "limit_price"
                                  ? `${strategyInfo.floorValue} ${strategyInfo.stableToken}`
                                  : ""}
                                {strategyInfo.floor === "decrease_by"
                                  ? `${strategyInfo.floorValue}% Loss`
                                  : ""}
                              </h3>
                            </div>
                          </li>
                        </>
                      )}

                      {strategyInfo.floor && strategyInfo.buy && (
                        <>
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Cancel at Floor</p>
                              <h3>
                                {strategyInfo.cancelOnFloor === true
                                  ? `Yes`
                                  : "No"}
                              </h3>
                            </div>
                          </li>
                        </>
                      )}

                      {strategyInfo.floor && (
                        <>
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Liquidate at Floor</p>
                              <h3>
                                {strategyInfo.liquidateOnFloor === true
                                  ? `Yes`
                                  : "No"}
                              </h3>
                            </div>
                          </li>
                        </>
                      )}

                      {strategyInfo.floor && strategyInfo.minimumLoss && (
                        <>
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Minimum Loss Threshold</p>
                              <h3>
                                {strategyInfo.minimumLoss}{" "}
                                {strategyInfo.stableToken}
                              </h3>
                            </div>
                          </li>
                        </>
                      )}

                      {strategyInfo.buy && (
                        <>
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Buy</p>
                              <h3>
                                {strategyInfo.buy === "limit_price"
                                  ? `${strategyInfo.buyValue} ${strategyInfo.stableToken}`
                                  : ""}

                                {strategyInfo.buy === "current_price"
                                  ? `Current Market Price`
                                  : ""}
                              </h3>
                            </div>
                          </li>
                        </>
                      )}

                      {strategyInfo.buyDCAValue && (
                        <>
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Dollar Cost Averaging</p>
                              <h3>Enabled</h3>
                            </div>
                          </li>
                        </>
                      )}

                      {strategyInfo.buyDCAValue && (
                        <>
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Buy Spread Amount</p>
                              <h3>
                                {strategyInfo.buyDCAValue}{" "}
                                {strategyInfo.buyDCAUnit === "fixed"
                                  ? strategyInfo.stableToken
                                  : "%"}
                              </h3>
                            </div>
                          </li>
                        </>
                      )}

                      {strategyInfo.buyTwap && (
                        <>
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Buy Every</p>
                              <h3>
                                {parseInt(strategyInfo.buyTwapTime, 10) > 1
                                  ? `${parseInt(
                                      strategyInfo.buyTwapTime,
                                      10
                                    )} ${
                                      strategyInfo.buyTwapTimeUnit === "1"
                                        ? "hours"
                                        : "days"
                                    }`
                                  : `${parseInt(
                                      strategyInfo.buyTwapTime,
                                      10
                                    )} ${
                                      strategyInfo.buyTwapTimeUnit === "1"
                                        ? "hour"
                                        : "day"
                                    }`}
                              </h3>
                            </div>
                          </li>
                        </>
                      )}

                      {strategyInfo.btdType && (
                        <>
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Buy Every</p>
                              <h3>
                                {strategyInfo.btdValue}
                                {strategyInfo.btdType === "increase_by"
                                  ? "% Spike"
                                  : ""}
                                {strategyInfo.btdType === "decrease_by"
                                  ? "% Dip"
                                  : ""}
                                {strategyInfo.btdType === "fixed_increase"
                                  ? " " + strategyInfo.stableToken + " Spike"
                                  : ""}
                                {strategyInfo.btdType === "fixed_decrease"
                                  ? " " + strategyInfo.stableToken + " Dip"
                                  : ""}
                              </h3>
                            </div>
                          </li>
                        </>
                      )}

                      {strategyInfo.sell && (
                        <>
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Sell</p>
                              <h3>
                                {strategyInfo.sell === "limit_price"
                                  ? `${strategyInfo.sellValue} ${strategyInfo.stableToken}`
                                  : ""}

                                {strategyInfo.sell === "current_price"
                                  ? `Current Market Price`
                                  : ""}

                                {strategyInfo.sell === "increase_by"
                                  ? `${strategyInfo.sellValue}% Profit`
                                  : ""}
                              </h3>
                            </div>
                          </li>
                        </>
                      )}

                      {strategyInfo.sell && strategyInfo.highSellValue && (
                        <>
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Sell Max Limit Price</p>
                              <h3>
                                {strategyInfo.highSellValue}{" "}
                                {strategyInfo.stableToken}
                              </h3>
                            </div>
                          </li>
                        </>
                      )}

                      {strategyInfo.sellDCAValue && (
                        <>
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Spread Sell Orders</p>
                              <h3>Enabled</h3>
                            </div>
                          </li>
                        </>
                      )}

                      {strategyInfo.sellDCAValue && (
                        <>
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Sell Spread Amount</p>
                              <h3>
                                {strategyInfo.sellDCAValue}{" "}
                                {strategyInfo.sellDCAUnit === "fixed"
                                  ? strategyInfo.stableToken
                                  : "%"}
                              </h3>
                            </div>
                          </li>
                        </>
                      )}

                      {strategyInfo.sellTwap && (
                        <>
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Sell Every</p>
                              <h3>
                                {parseInt(strategyInfo.sellTwapTime, 10) > 1
                                  ? `${parseInt(
                                      strategyInfo.sellTwapTime,
                                      10
                                    )} ${
                                      strategyInfo.sellTwapTimeUnit === "1"
                                        ? "hours"
                                        : "days"
                                    }`
                                  : `${parseInt(
                                      strategyInfo.sellTwapTime,
                                      10
                                    )} ${
                                      strategyInfo.sellTwapTimeUnit === "1"
                                        ? "hour"
                                        : "day"
                                    }`}
                              </h3>
                            </div>
                          </li>
                        </>
                      )}

                      {strategyInfo.str && (
                        <>
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Sell Every</p>
                              <h3>
                                {strategyInfo.strValue}
                                {strategyInfo.strType === "increase_by"
                                  ? "% Spike"
                                  : ""}
                                {strategyInfo.strType === "decrease_by"
                                  ? "% Dip"
                                  : ""}
                                {strategyInfo.strType === "fixed_increase"
                                  ? " " + strategyInfo.stableToken + " Spike"
                                  : ""}
                                {strategyInfo.strType === "fixed_decrease"
                                  ? " " + strategyInfo.stableToken + " Dip"
                                  : ""}
                              </h3>
                            </div>
                          </li>
                        </>
                      )}

                      {strategyInfo.sell && strategyInfo.buy && (
                        <>
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Recurring</p>
                              <h3>
                                {strategyInfo.completeOnSell === true
                                  ? `No`
                                  : "Yes"}
                              </h3>
                            </div>
                          </li>
                        </>
                      )}

                      {strategyInfo.sell && strategyInfo.minimumProfit && (
                        <>
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Minimum Profit Threshold</p>
                              <h3>
                                {strategyInfo.minimumProfit}{" "}
                                {strategyInfo.stableToken}
                              </h3>
                            </div>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <>
            <div className="text-center py-4 w-100">
              <CircularProgress />
            </div>
          </>
        )}
        {/*isEdit && <EditStrategy returnEdit={returnEdit} />*/}
      </div>
      <CustomSnackbar
        open={openSnackbar}
        onClose={handleClose}
        message={snackbarMsg}
        severity={isStrategyCancelled ? "success" : "error"}
      />
    </>
  );
};

export default StrategyInformation;
